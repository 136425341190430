function NotFoundPage() {
  return (
    <img
      className="scale-with-grid"
      src="uploads/pics/page-not-found.png"
      width={"98%"}
      height={"98%"}
      alt="PalletServices banner"
    />
  );
}

export default NotFoundPage;
