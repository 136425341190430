import SideBarVideo from "./SideBarVideo";
import { useNavigate } from "react-router-dom";

function TechnologyPage() {
  const navigate = useNavigate();

  return (
    <>
      <div id="container01" className="container">
        <div id="shadow-left" />
        <div className="belowHeader single-page clearfix">
          {/* left main*/}
          {/* banner start*/}
          <div className="row">
            <div className="sixteen columns white">
              <div className="">
                <div className="htmlarea-content-body bootstrap">
                  <div id="c-2648" className="csc-default">
                    <div className="topContentMedia">
                      <img
                        className="scale-with-grid"
                        src="uploads/pics/header-Technology.png"
                        width={1024}
                        height={266}
                        alt="Technology banner"
                      />
                    </div>
                  </div>
                </div>
                <div id="c-7279" className="csc-default">
                  <div className="phone">
                    <div id="c-4514" className="csc-default">
                      <div className="row clearfix">
                        <a
                          href="http://btmsweb.bagtrans.com.au"
                          className="quicklink quicklink-world half1 clearfix"
                          title="Login"
                        >
                          <span className="world" />
                          <span className="quicklink-text location-header">
                            <strong className="specialFont1">Login</strong>
                            <br style={{ marginBottom: 5 }} />
                            <span>POD, Track &amp; Trace</span>
                          </span>
                        </a>
                      </div>
                    </div>
                    <div className="row clearfix">
                      <a
                        href="Contact"
                        className="quicklink quicklink-search half1 clearfix quicklink-customLink"
                        title="Locations"
                      >
                        <span className="locate" />
                        <span className="quicklink-text">
                          <strong className="specialFont1">Locations</strong>
                          <br style={{ marginBottom: 5 }} />
                          BagTrans Pty Ltd
                        </span>
                      </a>
                      <a
                        href="TransportQuery.php"
                        title="Query"
                        className="quicklink quicklink-customLink half2 clearfix omega"
                      >
                        <span className="telephone" />
                        <span className="quicklink-text">
                          <strong className="specialFont1">Query</strong>
                          <br style={{ marginBottom: 5 }} />
                          Transport Query
                        </span>
                      </a>
                    </div>
                  </div>
                  {/*  phone end */}
                </div>
              </div>
            </div>
          </div>
          {/* Banner end*/}
          {/* under slider*/}
          <div className="row single clearfix">
            <div className="sixteen columns white">
              <div className="content-wrapper clearfix">
                <div className="teen columns element-wrapper white clearfix left">
                  <div className="single-content-wrapper2">
                    {/*TYPO3SEARCH_begin*/}
                    <div className="htmlarea-content-body bootstrap">
                      {/*TYPO3SEARCH_end*/}
                      <div className="breadcrumb clearfix" />
                      {/*TYPO3SEARCH_begin*/}
                      <div id="c-2646" className="csc-default">
                        <hgroup>
                          <h2 className="csc-firstHeader">Technology</h2>
                        </hgroup>
                      </div>
                      {/* sub menu start*/}
                      <div id="c-3127" className="csc-default">
                        <div className="row-fluid">
                          <div className="h_line" />
                          <p>
                            Ensuring BagTrans is at the forefront of technology
                            is paramount, which is why we have our own IT
                            development team. Our IT professionals are tasked to
                            purpose-build IT solutions that will benefit the
                            company and our customers. This is one of BagTrans'
                            strategies to set us apart from the other providers.
                          </p>
                        </div>
                      </div>
                      <div id="c-2646" className="csc-default">
                        <hgroup>
                          <h2 className="csc-firstHeader">
                            News and Information
                          </h2>
                        </hgroup>
                      </div>
                      <div className="row-fluid">
                        <div className="h_line" />
                        <p>
                          <strong>
                            BagTrans' Hawk-Eye GPS Tracking is a web-based
                            solution that adds a whole new dimension to modern
                            fleet management management by centralising vital
                            information.
                          </strong>
                        </p>
                        <p>Key features include:</p>
                        <ol>
                          <li
                            style={{ listStyleType: "disc", marginBottom: 4 }}
                          >
                            Web based system and is accessible from any
                            smartphone, tablet or computer.
                          </li>
                          <li
                            style={{ listStyleType: "disc", marginBottom: 4 }}
                          >
                            GPS tracking and historical replays enable viewing
                            of vehicle movements with greater ease of use.
                          </li>
                          <li
                            style={{ listStyleType: "disc", marginBottom: 4 }}
                          >
                            Waypoint (geo-fencing) management with overlayed
                            Google mapping showing satellite images as an
                            option.
                          </li>
                          <li
                            style={{ listStyleType: "disc", marginBottom: 4 }}
                          >
                            Fatigue management and Chain of responsibility
                            information data capture which now includes.
                          </li>
                          <li style={{ listStyleType: "disc" }}>
                            Performance dashboard monitoring:
                            <ol style={{ marginBottom: 0 }}>
                              <li style={{ listStyle: "circle" }}>
                                Driving speeds and instant email alerts.
                              </li>
                              <li style={{ listStyle: "circle" }}>
                                Hours of work.
                              </li>
                              <li style={{ listStyle: "circle" }}>
                                Distance travelled.
                              </li>
                            </ol>
                          </li>
                          <li
                            style={{ listStyleType: "disc", marginBottom: 4 }}
                          >
                            Streamlined reporting including:
                            <ol style={{ marginBottom: 0 }}>
                              <li style={{ listStyle: "circle" }}>
                                Waypoint exception reports.
                              </li>
                              <li style={{ listStyle: "circle" }}>
                                Fleet and driver point to point reports such as:
                                <ol style={{ marginBottom: 0 }}>
                                  <li style={{ listStyle: "square" }}>
                                    Trip reports by vehicle.
                                  </li>
                                  <li style={{ listStyle: "square" }}>
                                    Vehicle utilisation reports.
                                  </li>
                                </ol>
                              </li>
                            </ol>
                          </li>
                          <li
                            style={{ listStyleType: "disc", marginBottom: 4 }}
                          >
                            Non-conformance recording and corrective action
                            management:
                            <ol style={{ marginBottom: 0 }}>
                              <li style={{ listStyle: "circle" }}>
                                Currently used for fatigue management.
                              </li>
                            </ol>
                          </li>
                          <li
                            style={{ listStyleType: "disc", marginBottom: 4 }}
                          >
                            Exception and alerts reporting:
                            <ol style={{ marginBottom: 0 }}>
                              <li style={{ listStyle: "circle" }}>
                                Waypoint exception reporting.
                              </li>
                            </ol>
                          </li>
                          <li
                            style={{ listStyleType: "disc", marginBottom: 4 }}
                          >
                            Driver management reviews driving behaviour
                            utilising a points system.
                          </li>
                        </ol>
                        <img
                          src="uploads/pics/NewsInformation-img.png"
                          width="100%;"
                        />
                      </div>
                    </div>
                    {/*TYPO3SEARCH_end*/}
                  </div>
                </div>
                {<SideBarVideo />}
                <div id="c-3127" className="csc-default">
                  <div className="row-fluid white">
                    <div className="span4">
                      <div id="c-3130" className="csc-default">
                        {/* 1 */}
                        <div
                          className="overviewElement design-0 boxlink element"
                          onClick={() => navigate("/WebBasedDispatch")}
                        >
                          <span className="img-overlay">
                            <span className="img-overlay-bg">
                              <span className="img-overlay-wrapper">
                                <span className="img-overlay-head">
                                  Web Based - Dispatch
                                  <span className="slide-content-button" />
                                </span>
                                <span className="img-overlay-subhead">
                                  More Information
                                </span>
                              </span>
                            </span>
                            <img
                              className="scale-with-grid"
                              src="uploads/pics/sub-menu-9.png"
                              width={224}
                              height={190}
                              alt=""
                            />
                          </span>
                          <h3>
                            <a href="/WebBasedDispatch">Web Based Dispatch</a>
                          </h3>
                        </div>
                      </div>
                    </div>
                    {/* 1 end */}
                    <div className="span4">
                      <div id="c-3131" className="csc-default">
                        {/* 3 */}
                        <div
                          className="overviewElement design-0 boxlink element"
                          onClick={() => navigate("/GPSTracking")}
                        >
                          <span className="img-overlay">
                            <span className="img-overlay-bg">
                              <span className="img-overlay-wrapper">
                                <span className="img-overlay-head">
                                  GPS Tracking
                                  <span className="slide-content-button" />
                                </span>
                                <span className="img-overlay-subhead">
                                  More Information
                                </span>
                              </span>
                            </span>
                            <img
                              className="scale-with-grid"
                              src="uploads/pics/sub-menu-10.png"
                              width={224}
                              height={190}
                              alt=""
                            />
                          </span>
                          <h3>
                            <a href="/GPSTracking">GPS Tracking</a>
                          </h3>
                        </div>
                      </div>
                    </div>
                    {/* 2 end */}
                    <div className="span4">
                      <div id="c-3131" className="csc-default">
                        {/* 5 */}
                        <div
                          className="overviewElement design-0 boxlink element"
                          onClick={() => navigate("/OnlineProofOfDelivery")}
                        >
                          <span className="img-overlay">
                            <span className="img-overlay-bg">
                              <span className="img-overlay-wrapper">
                                <span className="img-overlay-head">
                                  Online Proof of Delivery
                                  <span className="slide-content-button" />
                                </span>
                                <span className="img-overlay-subhead">
                                  More Information
                                </span>
                              </span>
                            </span>
                            <img
                              className="scale-with-grid"
                              src="uploads/pics/sub-menu-11.png"
                              width={224}
                              height={190}
                              alt=""
                            />
                          </span>
                          <h3>
                            <a href="/OnlineProofOfDelivery">
                              Online Proof of Delivery
                            </a>
                          </h3>
                        </div>
                      </div>
                    </div>
                    {/* 3 end */}
                    <div className="span4">
                      <div id="c-3131" className="csc-default">
                        {/* 5 */}
                        <div
                          className="overviewElement design-0 boxlink element"
                          onClick={() => navigate("/TrackTrace")}
                        >
                          <span className="img-overlay">
                            <span className="img-overlay-bg">
                              <span className="img-overlay-wrapper">
                                <span className="img-overlay-head">
                                  Track &amp; Trace
                                  <span className="slide-content-button" />
                                </span>
                                <span className="img-overlay-subhead">
                                  More Information
                                </span>
                              </span>
                            </span>
                            <img
                              className="scale-with-grid"
                              src="uploads/pics/sub-menu-12.png"
                              width={224}
                              height={190}
                              alt=""
                            />
                          </span>
                          <h3>
                            <a href="/TrackTrace">Track &amp; Trace</a>
                          </h3>
                        </div>
                      </div>
                    </div>
                    {/* 4 end */}
                    <div className="span4" />
                  </div>
                  {/* c-3127 end here*/}
                </div>
                {/* after main hrader*/}
              </div>
            </div>
          </div>
          <div id="shadow-right" />
        </div>
      </div>
    </>
  );
}

export default TechnologyPage;
