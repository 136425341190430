import SideBarVideo from "./SideBarVideo";
import { useNavigate } from "react-router-dom";

function AboutUsPage() {
  const navigate = useNavigate();

  return (
    <>
      <div id="container01" className="container">
        <div id="shadow-left" />
        <div className="belowHeader single-page clearfix">
          {/* left main*/}
          {/* banner start*/}
          <div className="row">
            <div className="sixteen columns white">
              <div className="">
                <div className="htmlarea-content-body bootstrap">
                  <div id="c-2648" className="csc-default">
                    <div className="topContentMedia">
                      <img
                        className="scale-with-grid"
                        src="uploads/pics/header-news.png"
                        width={1024}
                        height={266}
                        alt="header banner"
                      />
                    </div>
                  </div>
                </div>
                <div id="c-7279" className="csc-default">
                  <div className="phone">
                    <div id="c-4514" className="csc-default">
                      <div className="row clearfix">
                        <a
                          href="http://btmsweb.bagtrans.com.au"
                          className="quicklink quicklink-world half1 clearfix"
                          title="Login"
                        >
                          <span className="world" />
                          <span className="quicklink-text location-header">
                            <strong className="specialFont1">Login</strong>
                            <br style={{ marginBottom: 5 }} />
                            <span>POD, Track &amp; Trace</span>
                          </span>
                        </a>
                      </div>
                    </div>
                    <div className="row clearfix">
                      <a
                        href="Contact"
                        className="quicklink quicklink-search half1 clearfix quicklink-customLink"
                        title="Locations"
                      >
                        <span className="locate" />
                        <span className="quicklink-text">
                          <strong className="specialFont1">Locations</strong>
                          <br style={{ marginBottom: 5 }} />
                          BagTrans Pty Ltd
                        </span>
                      </a>
                      <a
                        href="TransportQuery.php"
                        title="Query"
                        className="quicklink quicklink-customLink half2 clearfix omega"
                      >
                        <span className="telephone" />
                        <span className="quicklink-text">
                          <strong className="specialFont1">Query</strong>
                          <br style={{ marginBottom: 5 }} />
                          Transport Query
                        </span>
                      </a>
                    </div>
                  </div>
                  {/*  phone end */}
                </div>
              </div>
            </div>
          </div>
          {/* Banner end*/}
          {/* under slider*/}
          <div className="row single clearfix">
            <div className="sixteen columns white">
              <div className="content-wrapper clearfix">
                <div className="teen columns element-wrapper white clearfix left">
                  <div className="single-content-wrapper2">
                    {/*TYPO3SEARCH_begin*/}
                    <div className="htmlarea-content-body bootstrap">
                      {/*TYPO3SEARCH_end*/}
                      <div className="breadcrumb clearfix" />
                      {/*TYPO3SEARCH_begin*/}
                      <div id="c-2646" className="csc-default">
                        <hgroup>
                          <h2 className="csc-firstHeader">About Us</h2>
                        </hgroup>
                      </div>
                      {/* sub menu start*/}
                      <div id="c-3127" className="csc-default">
                        <div className="row-fluid white">
                          <div className="h_line" />
                          <p>
                            At BagTrans we understand the importance of meeting
                            the requirements of time-slotted and time-critical
                            delivery schedules. Our clients have complex and
                            demanding logistics requirements delivering FMCG to
                            grocery, retail and pharmacy major distribution
                            centres as well as food wholesalers and other key
                            delivery points nationally.
                          </p>
                          <p>
                            Our operational principles are to primarily engage
                            company-owned vehicles and drivers. We are pleased
                            to report approximately 85% of all freight handled
                            by BagTrans travels on BagTrans’ equipment,
                            providing the company with operational and
                            efficiency synergies resulting in exceptional KPI
                            results for our customers.
                          </p>
                        </div>
                      </div>
                    </div>
                    {/*TYPO3SEARCH_end*/}
                  </div>
                </div>
                {<SideBarVideo />}
                <div id="c-3127" className="csc-default">
                  <div className="row-fluid white">
                    <div className="span4">
                      <div id="c-3130" className="csc-default">
                        {/* 1 */}
                        <div
                          className="overviewElement design-0 boxlink element"
                          onClick={() => navigate("/CompanyProfile")}
                        >
                          <span className="img-overlay">
                            <span className="img-overlay-bg">
                              <span className="img-overlay-wrapper">
                                <span className="img-overlay-head">
                                  Company Profile
                                  <span className="slide-content-button" />
                                </span>
                                <span className="img-overlay-subhead">
                                  More Information
                                </span>
                              </span>
                            </span>
                            <img
                              className="scale-with-grid"
                              src="uploads/pics/sub-menu-5.png"
                              width={224}
                              height={190}
                              alt=""
                            />
                          </span>
                          <h3>
                            <a href="/CompanyProfile">Company Profile</a>
                          </h3>
                        </div>
                      </div>
                    </div>
                    {/* 1 end */}
                    <div className="span4">
                      <div id="c-3131" className="csc-default">
                        {/* 3 */}
                        <div
                          className="overviewElement design-0 boxlink element"
                          onClick={() => navigate("/OurVisionAndMission")}
                        >
                          <span className="img-overlay">
                            <span className="img-overlay-bg">
                              <span className="img-overlay-wrapper">
                                <span className="img-overlay-head">
                                  Our Vision
                                  <span className="slide-content-button" />
                                </span>
                                <span className="img-overlay-subhead">
                                  More Information
                                </span>
                              </span>
                            </span>
                            <img
                              className="scale-with-grid"
                              src="uploads/pics/sub-menu-6.png"
                              width={224}
                              height={190}
                              alt=""
                            />
                          </span>
                          <h3>
                            <a href="/OurVisionAndMission">
                              Our Vision and Mission
                            </a>
                          </h3>
                        </div>
                      </div>
                    </div>

                    <div className="span4">
                      <div id="c-3131" className="csc-default">
                        <div
                          className="overviewElement design-0 boxlink element"
                          onClick={() => navigate("/OurValues")}
                        >
                          <span className="img-overlay">
                            <span className="img-overlay-bg">
                              <span className="img-overlay-wrapper">
                                <span className="img-overlay-head">
                                  Our Values
                                  <span className="slide-content-button" />
                                </span>
                                <span className="img-overlay-subhead">
                                  More Information
                                </span>
                              </span>
                            </span>
                            <img
                              className="scale-with-grid"
                              src="uploads/pics/sub-menu-8.png"
                              width={224}
                              height={190}
                              alt=""
                            />
                          </span>
                          <h3>
                            <a href="/OurValues">Our Values</a>
                          </h3>
                        </div>
                      </div>
                    </div>
                    {/* 4 end */}
                    <div className="span4" />
                  </div>
                  {/* c-3127 end here*/}
                </div>
                {/* under header main contain */}
              </div>
            </div>
          </div>
          <div id="shadow-right" />
        </div>
      </div>
    </>
  );
}

export default AboutUsPage;
