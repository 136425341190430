import { useNavigate } from "react-router-dom";

function HomePage() {
  const navigate = useNavigate();

  return (
    <div className="row single clearfix">
      <div className="sixteen columns white">
        <div className="content-wrapper clearfix">
          <div className="element-wrapper clearfix left">
            {/*<div class="element-wrapper desktop clearfix left">*/}
            <div className="single-content-wrapper">
              {/*TYPO3SEARCH_begin*/}
              <div className="htmlarea-content-body bootstrap">
                {/*TYPO3SEARCH_end*/}
                <div className="breadcrumb clearfix" />
                {/*TYPO3SEARCH_begin*/}
                <div id="c-2646" className="csc-default">
                  <hgroup>
                    <h2 className="csc-firstHeader">BagTrans Pty Ltd</h2>
                    <p>
                      <strong>
                        “We have not chosen simply to be a transport provider -
                        we have chosen to be the best transport provider in
                        Australia. We aim to set the benchmark to which others
                        aspire.”
                      </strong>
                    </p>
                  </hgroup>
                  <p>
                    BagTrans' primary market focus is developed around one
                    central fact: that interstate time-slotted deliveries of
                    less than full loads are difficult to do - and therefore are
                    often not done well. BagTrans' primary market is medium to
                    large national and multinational companies with complex and
                    demanding transport requirements, delivering palletised
                    product to:
                  </p>
                  <ul>
                    <li>
                      Fast Moving Consumer Goods (FMCG) distribution centres;
                    </li>
                    <li>Pharmaceutical wholesalers;</li>
                    <li>Retail distribution centres, and</li>
                    <li>Consumer Durable distribution centres</li>
                  </ul>
                </div>
                {/* sub menu start*/}
                <div id="c-3127" className="csc-default">
                  <div className="row-fluid">
                    <div className="span4" />
                  </div>
                </div>
                {/* sub menu end*/}
                {/*TYPO3SEARCH_end*/}
              </div>
              {/*TYPO3SEARCH_end*/}
            </div>
          </div>
          {/* sider bar start */}
          <div id="sidebar" className="six columns">
            <div className="bootstrap">
              <div id="c-7279" className="csc-default">
                <aside id="sidebar-login" className="sidebar sidebar-slider">
                  <ul>
                    <li>
                      <span className="bubble">
                        <a
                          href="http://btmsweb.bagtrans.com.au/default.aspx?cmd=login"
                          title="BTMS Online Login"
                          target="_blank"
                        >
                          <span className="login" />
                          <strong>Login</strong>
                          <br />
                          POD, Track &amp; Trace
                        </a>
                      </span>
                    </li>
                    <li>
                      <span className="bubble">
                        <a
                          href="http://btmsweb.bagtrans.com.au/default.aspx?cmd=reg"
                          title="BTMS Online Registration"
                          target="_blank"
                        >
                          <span className="calculator" />
                          <strong>Login Registration</strong>
                          <br />
                          POD, Track &amp; Trace
                        </a>
                      </span>
                    </li>
                    <li>
                      <span className="bubble">
                        <a
                          href="TransportQuery.php"
                          title="Query"
                          className="customLink"
                        >
                          <span className="telephone" />
                          <strong>Query</strong>
                          <br />
                          Transport Query
                        </a>
                      </span>
                    </li>
                    <li>
                      <span className="bubble">
                        <a
                          href="Contact"
                          title="Locations"
                          className="customLink"
                        >
                          <span className="locate" />
                          <strong>Locations</strong>
                          <br />
                          BagTrans Pty Ltd
                        </a>
                      </span>
                    </li>
                  </ul>
                </aside>
                <div id="c-6224" className="csc-default" />
                <div id="c-4514" className="csc-default">
                  <aside id="sidebar-gw-world" className="boxlink sidebar">
                    <p>
                      Overview of the contact details of our national network
                    </p>
                    <img
                      className="scale-with-grid"
                      alt=""
                      src="media/layout/sidebar-gw-world-map.png"
                      style={{ maxWidth: "100%" }}
                    />
                    <a
                      className="normal-button orange"
                      href="Contact"
                      title="Find your contact within our national network"
                    >
                      Find address <span className="arrow-right" />{" "}
                    </a>
                    <br />
                    <p style={{ fontWeight: "bold" }}>
                      <a href="our-video">Watch our corporate video</a>
                    </p>
                    <a href="our-video">
                      <img src="media/layout/video-img.jpg" />
                    </a>
                  </aside>
                </div>
              </div>
              {/* right sider bar end*/}
              {/*left main end*/}
            </div>
          </div>
          <div id="c-3127" className="csc-default">
            <div className="row-fluid">
              <div className="span4">
                <div id="c-3130" className="csc-default">
                  {/* 1 */}
                  <div
                    className="overviewElement design-0 boxlink element"
                    onClick={() => navigate("/PalletServices")}
                  >
                    <span className="img-overlay">
                      <span className="img-overlay-bg">
                        <span className="img-overlay-wrapper">
                          <span className="img-overlay-head">
                            Pallet Services
                            <span className="slide-content-button" />
                          </span>
                          <span className="img-overlay-subhead">
                            More Information
                          </span>
                        </span>
                      </span>
                      <img
                        className="scale-with-grid"
                        src="uploads/pics/sub-menu-1.png"
                        width={224}
                        height={190}
                        alt=""
                      />
                    </span>
                    <h3>
                      <a href="/PalletServices">Pallet Services</a>
                    </h3>
                  </div>
                </div>
              </div>
              {/* 2 end */}
              {/* 3 end */}
              <div className="span4">
                <div id="c-3131" className="csc-default">
                  {/* 5 */}
                  <div
                    className="overviewElement design-0 boxlink element"
                    onClick={() => navigate("/RailServices")}
                  >
                    <span className="img-overlay">
                      <span className="img-overlay-bg">
                        <span className="img-overlay-wrapper">
                          <span className="img-overlay-head">
                            Rail Services
                            <span className="slide-content-button" />
                          </span>
                          <span className="img-overlay-subhead">
                            More Information
                          </span>
                        </span>
                      </span>
                      <img
                        className="scale-with-grid"
                        src="uploads/pics/sub-menu-4.png"
                        width={224}
                        height={190}
                        alt=""
                      />
                    </span>
                    <h3>
                      <a href="/RailServices">Rail Services</a>
                    </h3>
                  </div>
                </div>
              </div>
              {/* 4 end */}
              <div className="span4" />
            </div>
            {/* c-3127 end here*/}
          </div>
          {/* under header main contain */}
        </div>
      </div>
    </div>
  );
}

export default HomePage;
