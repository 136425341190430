function OurMissionPage() {
  return (
    <>
      <div id="container01" className="container">
        <div id="shadow-left" />

        <div className="belowHeader single-page clearfix">
          {/* left main*/}
          {/* under slider*/}
          <div className="row single clearfix">
            <div className="sixteen columns white">
              <div className="content-wrapper clearfix">
                <div className="teen columns element-wrapper white clearfix left">
                  <div className="single-content-wrapper2">
                    {/*TYPO3SEARCH_begin*/}
                    <div className="htmlarea-content-body bootstrap">
                      {/*TYPO3SEARCH_end*/}
                      <div className="breadcrumb clearfix" />
                      {/*TYPO3SEARCH_begin*/}
                      <div id="c-2646" className="csc-default">
                        <hgroup>
                          <h2 className="csc-firstHeader">Our Mission</h2>
                        </hgroup>
                      </div>
                      {/* sub menu start*/}
                      <div id="c-3127" className="csc-default">
                        <div className="row-fluid">
                          <div className="h_line" />
                          <p>
                            BagTrans provides its customers with a reliable,
                            on-time and cost-effective national palletised LTL
                            delivery service, primarily targeting customers with
                            palletised time-critical, fragile and high-value
                            products.
                          </p>
                          <p>
                            BagTrans’ continual investment in technology,
                            specialised equipment, operational systems and staff
                            development will further secure itself within the
                            targeted market niche (national palletised LTL
                            freight distribution).
                          </p>
                        </div>
                      </div>
                      {/* sub menu end*/}
                    </div>
                    {/*TYPO3SEARCH_end*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="shadow-right" />
      </div>
    </>
  );
}

export default OurMissionPage;
