import SideBarVideo from "./SideBarVideo";
import { useNavigate } from "react-router-dom";

function ServicesPage() {
  const navigate = useNavigate();

  return (
    <>
      <div id="container01" className="container">
        <div id="shadow-left" />
        <div className="belowHeader single-page clearfix">
          {/* left main*/}
          {/* banner start*/}
          <div className="row">
            <div className="sixteen columns white">
              <div className="">
                <div className="htmlarea-content-body bootstrap">
                  <div id="c-2648" className="csc-default">
                    <div className="topContentMedia">
                      <img
                        className="scale-with-grid"
                        src="uploads/pics/header-landverkehr-u_c1d0b6531b.png"
                        width={1024}
                        height={266}
                        alt="PalletServices banner"
                      />
                    </div>
                  </div>
                </div>
                <div id="c-7279" className="csc-default">
                  <div className="phone">
                    <div id="c-4514" className="csc-default">
                      <div className="row clearfix">
                        <a
                          href="http://btmsweb.bagtrans.com.au"
                          className="quicklink quicklink-world half1 clearfix"
                          title="Login"
                        >
                          <span className="world" />
                          <span className="quicklink-text location-header">
                            <strong className="specialFont1">Login</strong>
                            <br style={{ marginBottom: 5 }} />
                            <span>POD, Track &amp; Trace</span>
                          </span>
                        </a>
                      </div>
                    </div>
                    <div className="row clearfix">
                      <a
                        href="Contact"
                        className="quicklink quicklink-search half1 clearfix quicklink-customLink"
                        title="Locations"
                      >
                        <span className="locate" />
                        <span className="quicklink-text">
                          <strong className="specialFont1">Locations</strong>
                          <br style={{ marginBottom: 5 }} />
                          BagTrans Pty Ltd
                        </span>
                      </a>
                      <a
                        href="TransportQuery.php"
                        title="Query"
                        className="quicklink quicklink-customLink half2 clearfix omega"
                      >
                        <span className="telephone" />
                        <span className="quicklink-text">
                          <strong className="specialFont1">Query</strong>
                          <br style={{ marginBottom: 5 }} />
                          Transport Query
                        </span>
                      </a>
                    </div>
                  </div>
                  {/*  phone end */}
                </div>
              </div>
            </div>
          </div>
          {/* Banner end*/}
          {/* under slider*/}
          <div className="row single clearfix">
            <div className="sixteen columns white">
              <div className="content-wrapper clearfix">
                <div className="teen columns element-wrapper white clearfix left">
                  <div className="single-content-wrapper2">
                    {/*TYPO3SEARCH_begin*/}
                    <div className="htmlarea-content-body bootstrap">
                      {/*TYPO3SEARCH_end*/}
                      <div className="breadcrumb clearfix" />
                      {/*TYPO3SEARCH_begin*/}
                      <div id="c-2646" className="csc-default">
                        <hgroup>
                          <h2 className="csc-firstHeader">Services</h2>
                        </hgroup>
                      </div>
                      {/* sub menu start*/}
                      <div id="c-3127" className="csc-default">
                        <div className="row-fluid">
                          <div className="h_line" />
                          <p>
                            BagTrans offer transport services that are designed
                            to complement each other. We offer general and
                            express services for pallets via road and rail
                            throughout Australia.
                          </p>
                          <p>
                            For further details of the services we offer call
                            our Sales team on 1300 279 182 who will be pleased
                            to guide you to the right solution. Alternatively
                            email us at &nbsp;
                            <a href="mailto:sales@BagTrans.com.au">
                              sales@BagTrans.com.au
                            </a>
                            .
                          </p>
                        </div>
                      </div>
                    </div>
                    {/*TYPO3SEARCH_end*/}
                  </div>
                </div>
                {<SideBarVideo />}
                <div id="c-3127" className="csc-default">
                  <div className="row-fluid">
                    <div className="span4">
                      <div id="c-3130" className="csc-default">
                        {/* 1 */}
                        <div
                          className="overviewElement design-0 boxlink element"
                          onClick={() => navigate("/PalletServices")}
                        >
                          <span className="img-overlay">
                            <span className="img-overlay-bg">
                              <span className="img-overlay-wrapper">
                                <span className="img-overlay-head">
                                  Pallet Services
                                  <span className="slide-content-button" />
                                </span>
                                <span className="img-overlay-subhead">
                                  More Information
                                </span>
                              </span>
                            </span>
                            <img
                              className="scale-with-grid"
                              src="uploads/pics/sub-menu-1.png"
                              width={224}
                              height={190}
                              alt=""
                            />
                          </span>
                          <h3>
                            <a href="/PalletServices">Pallet Services</a>
                          </h3>
                        </div>
                      </div>
                    </div>
                    {/* 2 end */}
                    {/* 3 end */}
                    <div className="span4">
                      <div id="c-3131" className="csc-default">
                        {/* 5 */}
                        <div
                          className="overviewElement design-0 boxlink element"
                          onClick={() => navigate("/RailServices")}
                        >
                          <span className="img-overlay">
                            <span className="img-overlay-bg">
                              <span className="img-overlay-wrapper">
                                <span className="img-overlay-head">
                                  Rail Services
                                  <span className="slide-content-button" />
                                </span>
                                <span className="img-overlay-subhead">
                                  More Information
                                </span>
                              </span>
                            </span>
                            <img
                              className="scale-with-grid"
                              src="uploads/pics/sub-menu-4.png"
                              width={224}
                              height={190}
                              alt=""
                            />
                          </span>
                          <h3>
                            <a href="/RailServices">Rail Services</a>
                          </h3>
                        </div>
                      </div>
                    </div>
                    {/* 4 end */}
                    <div className="span4" />
                  </div>
                  {/* c-3127 end here*/}
                </div>
                {/* after main header*/}
              </div>
            </div>
          </div>
          <div id="shadow-right" />
        </div>
      </div>
    </>
  );
}

export default ServicesPage;
