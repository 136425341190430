import SideBarVideo from "./SideBarVideo";

function OurVisionPage() {
  return (
    <>
      <div id="container01" className="container">
        <div id="shadow-left" />

        <div className="belowHeader single-page clearfix">
          {/* left main*/}
          {/* banner start*/}
          <div className="row">
            <div className="sixteen columns white">
              <div className="">
                <div className="htmlarea-content-body bootstrap">
                  <div id="c-2648" className="csc-default">
                    <div className="topContentMedia">
                      <img
                        className="scale-with-grid"
                        src="uploads/pics/header-OurVision.png"
                        width={1024}
                        height={266}
                        alt="OurVision banner"
                      />
                    </div>
                  </div>
                </div>
                <div id="c-7279" className="csc-default">
                  <div className="phone">
                    <div id="c-4514" className="csc-default">
                      <div className="row clearfix">
                        <a
                          href="http://btmsweb.bagtrans.com.au"
                          className="quicklink quicklink-world half1 clearfix"
                          title="Login"
                        >
                          <span className="world" />
                          <span className="quicklink-text location-header">
                            <strong className="specialFont1">Login</strong>
                            <br style={{ marginBottom: 5 }} />
                            <span>POD, Track &amp; Trace</span>
                          </span>
                        </a>
                      </div>
                    </div>
                    <div className="row clearfix">
                      <a
                        href="Contact"
                        className="quicklink quicklink-search half1 clearfix quicklink-customLink"
                        title="Locations"
                      >
                        <span className="locate" />
                        <span className="quicklink-text">
                          <strong className="specialFont1">Locations</strong>
                          <br style={{ marginBottom: 5 }} />
                          BagTrans Pty Ltd
                        </span>
                      </a>
                      <a
                        href="TransportQuery.php"
                        title="Query"
                        className="quicklink quicklink-customLink half2 clearfix omega"
                      >
                        <span className="telephone" />
                        <span className="quicklink-text">
                          <strong className="specialFont1">Query</strong>
                          <br style={{ marginBottom: 5 }} />
                          Transport Query
                        </span>
                      </a>
                    </div>
                  </div>
                  {/*  phone end */}
                </div>
              </div>
            </div>
          </div>
          {/* Banner end*/}
          {/* under slider*/}
          <div className="row single clearfix">
            <div className="sixteen columns white">
              <div className="content-wrapper clearfix">
                <div className="teen columns element-wrapper white clearfix left">
                  <div className="single-content-wrapper2">
                    {/*TYPO3SEARCH_begin*/}
                    <div className="htmlarea-content-body bootstrap">
                      {/*TYPO3SEARCH_end*/}
                      <div className="breadcrumb clearfix" />
                      {/*TYPO3SEARCH_begin*/}
                      <div id="c-2646" className="csc-default">
                        <hgroup>
                          <h2 className="csc-firstHeader">Our Vision</h2>
                        </hgroup>
                      </div>
                      {/* sub menu start*/}
                      <div id="c-3127" className="csc-default">
                        <div className="row-fluid">
                          <div className="h_line" />
                          <p>
                            BagTrans will be recognised as the benchmark
                            national palletised time-critical LTL supplier by
                            customers in the wholesale food, FMCG and
                            pharmaceutical market segments.
                          </p>
                          <p>
                            This recognition will be based upon cost-effective,
                            sustainable, exceptional levels of customer service,
                            whilst operating to the highest standards of health,
                            safety and environmental protection.
                          </p>
                        </div>
                      </div>
                      {/* sub menu end*/}
                    </div>
                    {/*TYPO3SEARCH_end*/}
                  </div>
                </div>
                {<SideBarVideo />}
              </div>
            </div>
          </div>
        </div>
        <div id="shadow-right" />
      </div>
    </>
  );
}

export default OurVisionPage;
