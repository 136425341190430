import { Routes, Route } from "react-router-dom";
import HomePage from "./HomePage";
import Slider from "./Slider";
import OurVisionAndMissionPage from "./OurVisionAndMissionPage";
import CompanyProfilePage from "./CompanyProfilePage";
import OurValuesPage from "./OurValuesPage";
import TermsAndConditionsPage from "./TermsAndConditionsPage";
import PalletServicesPage from "./PalletServicesPage";
import RailServicesPage from "./RailServicesPage";
import WebBasedDispatchPage from "./WebBasedDispatchPage";
import GPSTrackingPage from "./GPSTrackingPage";
import OnlineProofOfDeliveryPage from "./OnlineProofOfDeliveryPage";
import TrackAndTracePage from "./TrackAndTracePage";
import ChainOfResponsibilityPage from "./ChainOfResponsibilityPage";
import ReportingPage from "./ReportingPage";
import AboutUsPage from "./AboutUsPage";
import ServicesPage from "./ServicesPage";
import TechnologyPage from "./TechnologyPage";
import SafetyAndCompliancePage from "./SafetyAndCompliancePage";
import ClientLoginPage from "./ClientLoginPage";
import ContactPage from "./ContactPage";
import NotFoundPage from "./NotFoundPage";
import OurVideoPage from "./OurVideoPage";
import SiteMapPage from "./SiteMapPage";
import PrivacyPolicyPage from "./PrivacyPolicyPage";

function App() {
  // return <HomePage />;
  return (
    <Routes>
      <Route
        exact
        path="/"
        element={
          <>
            <Slider />
            <HomePage />
          </>
        }
      />
      <Route
        exact
        path="/OurVisionAndMission"
        element={<OurVisionAndMissionPage />}
      />
      <Route exact path="/CompanyProfile" element={<CompanyProfilePage />} />
      <Route exact path="/OurValues" element={<OurValuesPage />} />
      <Route exact path="/RailServices" element={<RailServicesPage />} />
      <Route exact path="/GPSTracking" element={<GPSTrackingPage />} />
      <Route exact path="/TrackTrace" element={<TrackAndTracePage />} />
      <Route exact path="/reporting" element={<ReportingPage />} />
      <Route exact path="/AboutUs" element={<AboutUsPage />} />
      <Route exact path="/Services" element={<ServicesPage />} />
      <Route exact path="/Technology" element={<TechnologyPage />} />
      <Route exact path="/ClientLogin" element={<ClientLoginPage />} />
      <Route exact path="/Contact" element={<ContactPage />} />
      <Route exact path="/our-video" element={<OurVideoPage />} />
      <Route exact path="/sitemap" element={<SiteMapPage />} />
      <Route exact path="/Privacy-Policy" element={<PrivacyPolicyPage />} />

      <Route
        exact
        path="/SafetyAndCompliance"
        element={<SafetyAndCompliancePage />}
      />

      <Route
        exact
        path="/ChainofResponsibility"
        element={<ChainOfResponsibilityPage />}
      />
      <Route
        exact
        path="/OnlineProofOfDelivery"
        element={<OnlineProofOfDeliveryPage />}
      />

      <Route
        exact
        path="/WebBasedDispatch"
        element={<WebBasedDispatchPage />}
      />

      <Route
        exact
        path="/TermsAndConditions"
        element={<TermsAndConditionsPage />}
      />
      <Route exact path="/PalletServices" element={<PalletServicesPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default App;
