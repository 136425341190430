import SideBarVideo from "./SideBarVideo";

function ReportingPage() {
  return (
    <>
      <div id="container01" className="container">
        <div id="shadow-left" />
        <div className="belowHeader single-page clearfix">
          {/* left main*/}
          {/* banner start*/}
          <div className="row">
            <div className="sixteen columns white">
              <div className="">
                <div className="htmlarea-content-body bootstrap">
                  <div id="c-2648" className="csc-default">
                    <div className="topContentMedia">
                      <img
                        className="scale-with-grid"
                        src="uploads/pics/header-Reporting.png"
                        width={1024}
                        height={266}
                        alt="Reporting banner"
                      />
                    </div>
                  </div>
                </div>
                <div id="c-7279" className="csc-default">
                  <div className="phone">
                    <div id="c-4514" className="csc-default">
                      <div className="row clearfix">
                        <a
                          href="http://btmsweb.bagtrans.com.au"
                          className="quicklink quicklink-world half1 clearfix"
                          title="Login"
                        >
                          <span className="world" />
                          <span className="quicklink-text location-header">
                            <strong className="specialFont1">Login</strong>
                            <br style={{ marginBottom: 5 }} />
                            <span>POD, Track &amp; Trace</span>
                          </span>
                        </a>
                      </div>
                    </div>
                    <div className="row clearfix">
                      <a
                        href="Contact"
                        className="quicklink quicklink-search half1 clearfix quicklink-customLink"
                        title="Locations"
                      >
                        <span className="locate" />
                        <span className="quicklink-text">
                          <strong className="specialFont1">Locations</strong>
                          <br style={{ marginBottom: 5 }} />
                          BagTrans Pty Ltd
                        </span>
                      </a>
                      <a
                        href="TransportQuery.php"
                        title="Query"
                        className="quicklink quicklink-customLink half2 clearfix omega"
                      >
                        <span className="telephone" />
                        <span className="quicklink-text">
                          <strong className="specialFont1">Query</strong>
                          <br style={{ marginBottom: 5 }} />
                          Transport Query
                        </span>
                      </a>
                    </div>
                  </div>
                  {/*  phone end */}
                </div>
              </div>
            </div>
          </div>
          {/* Banner end*/}
          {/* under slider*/}
          <div className="row single clearfix">
            <div className="sixteen columns white">
              <div className="content-wrapper clearfix">
                <div className="teen columns element-wrapper white clearfix left">
                  <div className="single-content-wrapper2">
                    {/*TYPO3SEARCH_begin*/}
                    <div className="htmlarea-content-body bootstrap">
                      {/*TYPO3SEARCH_end*/}
                      <div className="breadcrumb clearfix" />
                      {/*TYPO3SEARCH_begin*/}
                      <div id="c-2646" className="csc-default">
                        <hgroup>
                          <h2 className="csc-firstHeader">Reporting</h2>
                        </hgroup>
                      </div>
                      {/* sub menu start*/}
                      <div id="c-3127" className="csc-default">
                        <div className="row-fluid">
                          <div className="h_line" />
                          <p>
                            BagTrans has developed a suite of key performance
                            indicators and full delivery cost-analysis reports
                            that are second to none in the transport industry.
                            The information we provide to our clients has been
                            heralded as “best in class” and invaluable to the
                            ongoing success of their logistics, sales and
                            financial department synergies relating to
                            consolidation and cost reduction opportunities.
                          </p>
                          <img src="uploads/pics/reporting1.gif" width="100%" />
                          <img src="uploads/pics/reporting2.gif" width="100%" />
                          <br />
                          <br />
                          <p>
                            For a complete understanding of the BagTrans
                            commitment to our employees call 1300 279 182.
                          </p>
                        </div>
                      </div>
                    </div>
                    {/*TYPO3SEARCH_end*/}
                  </div>
                </div>
                {<SideBarVideo />}
                <div id="c-3127" className="csc-default">
                  <div className="row-fluid white">
                    <div className="span4" />
                  </div>
                  {/* c-3127 end here*/}
                </div>
                {/* after main hrader*/}
              </div>
            </div>
          </div>
          <div id="shadow-right" />
        </div>
      </div>
    </>
  );
}

export default ReportingPage;
