import SideBarVideo from "./SideBarVideo";

function PrivacyPolicyPage() {
  return (
    <>
      <div id="container01" className="container">
        <div id="shadow-left" />

        <div className="belowHeader single-page clearfix">
          <div className="row">
            <div className="sixteen columns white">
              <div className="">
                <div className="htmlarea-content-body bootstrap">
                  <div id="c-2648" className="csc-default">
                    <div className="topContentMedia">
                      <img
                        className="scale-with-grid"
                        src="uploads/pics/header-PrivacyPolicy.png"
                        width={1024}
                        height={266}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div id="c-7279" className="csc-default">
                  <div className="phone">
                    <div id="c-4514" className="csc-default">
                      <div className="row clearfix">
                        <a
                          href="http://btmsweb.bagtrans.com.au"
                          className="quicklink quicklink-world half1 clearfix"
                          title="Login"
                        >
                          <span className="world" />
                          <span className="quicklink-text location-header">
                            <strong className="specialFont1">Login</strong>
                            <br style={{ marginBottom: 5 }} />
                            <span>POD, Track &amp; Trace</span>
                          </span>
                        </a>
                      </div>
                    </div>
                    <div className="row clearfix">
                      <a
                        href="Contact"
                        className="quicklink quicklink-search half1 clearfix quicklink-customLink"
                        title="Locations"
                      >
                        <span className="locate" />
                        <span className="quicklink-text">
                          <strong className="specialFont1">Locations</strong>
                          <br style={{ marginBottom: 5 }} />
                          BagTrans Pty Ltd
                        </span>
                      </a>
                      <a
                        href="TransportQuery.php"
                        title="Query"
                        className="quicklink quicklink-customLink half2 clearfix omega"
                      >
                        <span className="telephone" />
                        <span className="quicklink-text">
                          <strong className="specialFont1">Query</strong>
                          <br style={{ marginBottom: 5 }} />
                          Transport Query
                        </span>
                      </a>
                    </div>
                  </div>
                  {/*  phone end */}
                </div>
              </div>
            </div>
          </div>
          {/* Banner end*/}
          {/* under slider*/}
          <div className="row single clearfix">
            <div className="sixteen columns white">
              <div className="content-wrapper clearfix">
                <div className="teen columns element-wrapper white clearfix left">
                  <div className="single-content-wrapper2">
                    {/*TYPO3SEARCH_begin*/}
                    <div className="htmlarea-content-body bootstrap">
                      <div id="c-2646" className="csc-default">
                        <hgroup>
                          <h2 className="csc-firstHeader">Privacy Policy</h2>
                        </hgroup>
                      </div>
                      {/* sub menu start*/}
                      <div id="c-3127" className="csc-default">
                        <div className="row-fluid">
                          <div className="h_line" />
                          <p>
                            This Privacy Policy applies to the management of
                            your personal information (<b>PI</b>) by or on
                            behalf of Freight Management Holdings Pty Ltd (ACN
                            144 724 532) and its related bodies corporate
                            including without limitation BagTrans Pty Ltd, Niche
                            Logistics Pty Ltd, Flemington Fields Pty Ltd trading
                            as GKR Transport, Logistics Holdings Australia Pty
                            Ltd, Logistics Holdings Industrial Pty Ltd and
                            Andromeda Nominees Pty Ltd trading as Formby
                            Logistics (<b>we, us, our</b>).
                          </p>
                          <p>
                            We have created this privacy policy in order to
                            demonstrate our commitment to the Privacy Act 1988
                            Cth (Privacy Act) and the Australian Privacy
                            Principles (<b>APPs</b>) (together, the{" "}
                            <b>Australian Privacy Law</b>). It sets out how we
                            may collect, hold, use or disclose your PI. This
                            policy includes examples but is not intended to be
                            restricted in its application to such examples.
                            Where the word 'including' is used, it shall mean
                            'including without limitation'.
                          </p>
                          <h5>What PI do we collect?</h5>
                          <p>
                            'Personal Information' is information or opinion
                            about an identified individual, or an individual who
                            is reasonably identifiable. We may collect and hold
                            PI such as your name, phone number or mobile
                            telephone number, email, IP address, physical
                            address, occupation, financial information,
                            employment history, gender, professional
                            qualifications, licences held, residency status,
                            date of birth, criminal records, tax file numbers,
                            forklift or driver’s licence or passport numbers,
                            telematic and other fatigue management information
                            collected from our vehicles, and bank details. We
                            may also collect financial information, including
                            credit reports and related credit information and
                            credit eligibility information, information about
                            your income, bank(s) or financial circumstances and
                            other information we need to verify you. Some of our
                            premises have closed circuit TV systems or other
                            monitoring devices which may record you if you visit
                            our premises, for security and safety purposes.{" "}
                          </p>
                          <p>
                            Our policy is to only collect your sensitive
                            information (such as health information of
                            prospective and current employees) where it is
                            reasonably necessary for our functions or activities
                            and either you have consented, or we are required or
                            authorised under law to do so.{" "}
                          </p>
                          <h5>How we collect your PI</h5>
                          <p>
                            We collect your PI as part of us offering or
                            providing our products or services. This includes
                            when you provide your PI to us via our website,
                            during phone or video calls (calls may be recorded
                            for training and/or quality purposes, including
                            transcripts), when you apply for credit terms, or
                            otherwise via customer dealings or enquiries. We may
                            also collect PI when you create an account with us
                            or use our technology such as our warehouse and/or
                            freight management systems (including those licenced
                            to us by third parties) (<b>“Systems”</b>
                            ). Generally, we collect your PI directly from you,
                            but we may also collect your PI from other people or
                            organisations if you have given consent to do so.
                          </p>
                          <p>
                            We may collect PI through third parties, including
                            from recruiters or, if you have an item sent to you
                            by someone who uses our services. For example, when
                            you send an item using our services or someone else
                            sends an item using our services that is addressed
                            to you, we will receive the details of the item
                            including the weight and dimensions of the item and
                            other shipping information, including the sender and
                            recipient details. This information allows us to
                            provide our products and services and to respond to
                            queries from the sender.
                          </p>
                          <p>
                            We may also collect credit-related information from
                            credit reporting bodies (see details in our Credit
                            Reporting Policy below) or from other credit
                            providers where permitted by the Privacy Act.
                          </p>
                          <h5>Why do we need your PI?</h5>
                          <p>
                            We only collect or hold your PI where it is
                            reasonably necessary for our business functions or
                            activities.In particular, we need your PI to provide
                            you with our products and/or services which includes
                            communicating with you (such as via direct
                            marketing) about our products and/or services, for
                            quality or training purposes, administrative
                            purposes including administering your account with
                            us, creating and updating our database(s),
                            invoicing, billing &amp; debt recovery functions, to
                            resolve disputes, ensuring compliance with our
                            contractual and other legal and regulatory
                            obligations, bring and defend legal claims, to
                            determine if we will accept your credit application
                            and any guarantees provided, assessing credit
                            worthiness, reviewing existing credit terms,
                            internal record-keeping, internal management
                            purposes, and administering our relationship with
                            you including by responding to your enquiries (Main
                            Purposes).{" "}
                          </p>
                          <h5>How do we use your PI?</h5>
                          <p>
                            By submitting your PI to us, you consent to us using
                            your PI for:
                          </p>
                          <div className="lower-alpha">
                            <ol>
                              <li>the Main Purposes; </li>
                              <li>
                                any purpose related to one of the Main Purposes,
                                and any purpose that could reasonably be
                                expected at the time your PI was collected (
                                <b>Secondary Purpose)</b>;
                              </li>
                              <li>
                                any purpose to which you otherwise consent
                                (including as disclosed to you in an information
                                collection statement at the point where we
                                collect your PI); and{" "}
                              </li>
                              <li>
                                any other purpose required or authorised by law
                                (including the Heavy Vehicle National Law and
                                Regulations and Australian Privacy Laws).
                              </li>
                            </ol>
                          </div>
                          <p>
                            If you do not consent to our collection and use of
                            your PI, we may not be able to carry out some or all
                            of the Main Purposes, or provide you with products,
                            services or information.{" "}
                          </p>
                          <p />
                          <p>
                            Secondary Purposes may include: providing you with
                            or improving and maintaining our products or
                            services (which includes accessing call recordings
                            and/or transcripts of call recordings for training
                            and quality purposes); sending you direct marketing
                            about our products or services, deals and
                            promotions; conducting customer surveys; managing
                            our relationship with you; monitoring how you
                            interact with us on our website or other contact
                            points and helping you to complete an activity that
                            you have chosen to undertake.
                          </p>
                          <p>
                            We may use certain non-personally identifiable
                            information (such as anonymous usage data, IP
                            addresses, browser type etc.) to improve the user
                            experience, quality and design of our products and
                            services, our website or our Systems (and including
                            the security and stability of our website or
                            Systems), and to help us better understand the needs
                            of our customers so that we and third parties can
                            better develop products and services for you, create
                            new features, promotions, functionality and services
                            by storing, tracking, analysing and processing user
                            preferences and trends as well as user activity and
                            communications. We may also disclose your PI to
                            other businesses owned by us for use in the manners
                            described above. We utilise 3rd party platforms to
                            provide anonymised analytics and insights regarding
                            platform and website performance and usage patterns
                            of users.
                          </p>
                          <h5>Can you remain anonymous or use a pseudonym?</h5>
                          <p>
                            We will, if practicable, allow you to use a
                            pseudonym or to not identify yourself (unless this
                            is impractical or against the law (including the
                            Australian Privacy Law).
                          </p>
                          <p>
                            In some instances, if you do not provide us with
                            certain PI we may not be able to provide you with
                            the relevant product, service or information. This
                            may have an effect on whether we can begin or
                            continue a relationship with you or provide our
                            products and services to you.
                          </p>
                          <h5>What disclosures will we make?</h5>
                          <p>
                            Generally speaking, we will disclose your PI for the
                            Main Purposes. We may also disclose your PI for the
                            Secondary Purposes, any purpose to which you
                            otherwise consent (including as disclosed to you in
                            any information collection statement at the point
                            where we collect your PI) and any other purpose
                            required or authorised by law (including the
                            Australian Privacy Laws).
                          </p>
                          <p>
                            We may disclose your PI to our related entities
                            (including any directors or shareholder that may
                            reside outside of Australia, including in countries
                            such as Singapore), our professional advisors
                            (including lawyers, accountants and auditors),
                            credit-reporting bodies for identity checking and
                            credit related purposes such as credit-worthiness
                            (for more information see the Credit Reporting
                            Policy below) and to our partners, suppliers and
                            distributors in order to assist us in providing our
                            products or services to you.
                          </p>
                          <p>
                            Some of our service providers, or the services they
                            provide (like cloud storage services), or our
                            directors or shareholders may be based outside
                            Australia. In order to protect PI, we take care
                            where possible to work with service providers who we
                            consider maintain acceptable standards of data
                            security compliance and we do our part to meet those
                            standards as they apply to us. By providing us with
                            PI, you consent to PI being used, stored and
                            disclosed overseas (and acknowledge that no
                            additional obligations that may apply to the
                            overseas disclosure of personal information under
                            Australian Privacy Laws will apply).
                          </p>
                          <h5>Is your PI confidential and secure?</h5>
                          <p>
                            We take all reasonable steps to keep the PI we hold
                            secure and to ensure it is protected against misuse,
                            loss, unauthorised access, modification or
                            inappropriate disclosure. We may hold PI in both
                            hard copy and electronic forms or other recorded
                            forms and will do so in secure systems accessible
                            only to authorised personnel.
                          </p>
                          <h5>Do we use “cookies”?</h5>
                          <p>
                            When you visit our website(s), our service may tell
                            your browser to store a "cookie" on your computer. A
                            cookie is a small text file that can be used to
                            store user preferences and information that allows
                            our services to identify you when you're logged in
                            or visiting our website. We, or 3rd party platforms
                            we utilise, may use cookies to track and analyse
                            your user experience to better understand what
                            products or services may be of interest to you and
                            to collect statistical information. We may also use
                            cookies to store a user's username and email address
                            on their computer for future visits to our website.
                          </p>
                          <h5>Using other sites</h5>
                          <p>
                            This site may contain links to other websites.
                            Unless the other website is one of our sites, we are
                            not responsible for the privacy practices of the
                            owners of those websites. We recommend that you read
                            the privacy policy of any website that asks you to
                            provide your PI.
                          </p>
                          <h5>Changes to our Privacy Policy</h5>
                          <p>
                            We may need to change this Privacy Policy from time
                            to time. When we do amend it, the changes will be
                            effective immediately upon being made public on our
                            website. We will use reasonable measures to notify
                            you of any relevant changes to this privacy policy,
                            but please be aware that it is your responsibility
                            to review our Privacy Policy regularly and make sure
                            you keep up to date with any changes.
                          </p>
                          <h5>
                            Contacting us about privacy and accessing your
                            personal information
                          </h5>
                          <p>
                            You can access your PI subject to certain
                            limitations under the Australian Privacy Law. In
                            most cases, a summary of your PI is available free
                            of charge. We encourage you to contact us to update
                            your PI to ensure that it is accurate, current and
                            complete. We will take reasonable steps to process
                            your request.
                          </p>
                          <p>
                            As a security measure, we may require you to confirm
                            your identity before access to your PI is granted.
                            If necessary, reasonable costs may be charged to you
                            in accessing your PI, subject always to the
                            Australian Privacy Law.
                          </p>
                          <p>
                            If you have a question or want to make a complaint
                            about how we handle your PI, please contact us using
                            the details below. We will review all queries and
                            complaints received and will respond to each
                            query/complaint upon due consideration (which may
                            require further information from you). We will,
                            where reasonably possible, take steps to investigate
                            and resolve complaints within 30 days. If we need
                            more time, we will notify you about the reasons for
                            the delay and seek to agree a longer period with you
                            (if you do not agree, we may not be able to resolve
                            your complaint). If we cannot resolve a complaint
                            related to your PI, you may contact the Office of
                            the Australian Information Commissioner (“OAIC”)
                            directly.
                          </p>
                          <p>We can be contacted via:</p>
                          <p>
                            Email:{" "}
                            <a href="mailto: Privacy@logisticsholdings.com.au">
                              Privacy@logisticsholdings.com.au
                            </a>
                          </p>
                          <p>
                            Phone: <a href="tel:1300 769 605">1300 769 605</a>
                          </p>
                          <p></p>
                          <address>
                            Post: C/- Freight Management Holdings Pty Ltd,
                            Attention: Group General Counsel, Morris Moor,
                            Ground Floor, 1 Cochranes Road, Moorabbin, VIC 3189
                          </address>
                          <p />
                          <p>
                            Or for more information about privacy issues in
                            Australia and protecting your privacy, please visit
                            the OAIC website at{" "}
                            <a href="https://www.oaic.gov.au/" target="_blank">
                              www.oaic.gov.au
                            </a>
                            .
                          </p>
                          <h2 className="csc-firstHeader">
                            Credit Reporting Policy
                          </h2>
                          <div className="h_line" />
                          <p>
                            We may provide credit as contemplated in the Privacy
                            Act to our customers in connection with our products
                            and services (for example, credit term accounts). We
                            therefore may collect credit information and credit
                            eligibility information about you or give this
                            information to credit reporting bodies as set out in
                            the Privacy Act 1988. Our Credit Reporting Policy
                            sets out how we manage that information.
                          </p>
                          <p>
                            This credit reporting policy relates to Freight
                            Management Holdings Pty Ltd (ACN 144 724 532) and
                            its related bodies corporate including BagTrans Pty
                            Ltd, Niche Logistics Pty Ltd, Flemington Fields Pty
                            Ltd trading as GKR Transport, Logistics Holdings
                            Australia Pty Ltd, Logistics Holdings Industrial Pty
                            Ltd and Andromeda Nominees Pty Ltd trading as Formby
                            Logistics (<b>we, us, our</b>) that are deemed to be
                            credit providers or affected information recipients
                            for the purposes of the Privacy Act 1988 (Cth).
                          </p>
                          <h5>
                            What credit information and credit eligibility
                            information do we collect?
                          </h5>
                          <p>
                            We may collect credit information about you in
                            connection with an application for credit and in any
                            of the circumstances relating to other personal
                            information detailed in the “What PI do we collect?”
                            section of our Privacy Policy. If you apply for any
                            kind of credit or offer to act as guarantor, we may
                            disclose your personal information to, or collect
                            personal information about you from, a credit
                            reporting body.
                          </p>
                          <p>
                            Credit information can include (a) identification
                            information; (b) information relating primarily to
                            your credit related dealings with us and covers
                            various types of information that can be collected
                            by Credit Reporting Bodies (“CRBs”) that report on
                            consumer credit worthiness including your or your
                            guarantees’ financial position; (c) financial
                            information relating to companies seeking credit
                            terms, directors, partners, trustees, sole traders
                            and any person who acts or proses to act as a
                            guarantor; (d) basic information about your credit
                            account including repayment history information; (e)
                            historical solvency information of directors,
                            partners, trustees, sole traders or managers
                            associated with a business applying for credit (f)
                            details about information requests we make about you
                            to CRBs, (g) information about certain overdue
                            payments, serious credit infringements; and (h)
                            various publicly available information like
                            bankruptcy and credit-related court judgements.
                          </p>
                          <p>
                            Credit eligibility information is information
                            equivalent to the types set out above that are
                            generally obtained from CRBs. This information
                            relates primarily to your dealings with other credit
                            providers (i.e. banks, other financial institutions
                            and entities that may provide you with credit in
                            connection with their goods or services). It may
                            also include certain credit worthiness information
                            that is derived from data collected from a CRB or
                            other credit reporters.
                          </p>
                          <p>
                            We store and secure your credit information and
                            credit eligibility information in the manner
                            described in the “Is your PI confidential and
                            secure?” section of our Privacy Policy.
                          </p>
                          <h5>
                            How do we use and disclose your credit information
                            and credit eligibility information?
                          </h5>
                          <p>
                            Credit information and credit eligibility
                            information disclosed to us is principally used for
                            in connection with your application for credit to
                            assess that application and for the ongoing
                            management of a credit account and otherwise as
                            permitted by law. This may involve any of the
                            following uses:
                          </p>
                          <div className="lower-alpha">
                            <ol>
                              <li>
                                processing credit-related applications
                                (including assessing your credit worthiness and
                                assessing credit guarantees (current and
                                prospective)) and includes obtaining consumer
                                and commercial credit reports from CRBs) and
                                managing the ongoing credit that we provide;
                              </li>
                              <li>
                                assisting you if we think you may be at risk of
                                default;
                              </li>
                              <li>
                                collecting amounts you may owe us in relation to
                                such credit and dealing with serious credit
                                infringements;
                              </li>
                              <li>
                                providing or exchanging personal information
                                with any person whose name is provided to us in
                                connection with your application for credit
                                (including trade references);
                              </li>
                              <li>internal management purposes;</li>
                              <li>
                                participating in the credit reporting system and
                                providing information to CRBs as permitted by
                                the Privacy Act 1988;
                              </li>
                              <li>
                                dealing with complaints or regulatory matters
                                relating to credit or credit reporting; and
                              </li>
                              <li>
                                as required or permitted by the Privacy Act 1988
                                or another law.
                              </li>
                            </ol>
                          </div>
                          <p>
                            We may disclose your credit information to CRBs who
                            may then include that information in credit reports
                            they provide to other credit providers to assist
                            those credit providers assessing your credit
                            worthiness.
                          </p>
                          <p>
                            We may disclose your credit information to our debt
                            recovery agents, who may use this to assist us in
                            recovering debt owed to us.
                          </p>
                          <p>
                            We may also use and disclose your credit information
                            for other purposes and in other circumstances as set
                            out in the “How do we use your PI?” and “What
                            disclosure will we make?” sections of our Privacy
                            Policy, when permitted to do so by the Privacy Act
                            1988.
                          </p>
                          <p>
                            In some cases, the people to whom we may disclose
                            your credit information or credit eligibility
                            information may be based outside Australia – for
                            more information please see the details under the
                            “What disclosure will we make?” section of our
                            Privacy Policy.
                          </p>
                          <h5>
                            Credit reporting bodies to who we may share credit
                            –related personal information include:
                          </h5>
                          <div className="lower-alpha">
                            <ol>
                              <li>
                                National Credit Insurance (Brokers) Pty Ltd,
                                7/432 St Kilda Rd, Melbourne VIC 3004, Tel: 1800
                                882 820, E: info@nci.com.au, www.nci.com.au{" "}
                              </li>
                              <li>
                                Equifax Australian Group Pty Ltd (formerly known
                                as Veda), PO Box 964, North Sydney NSW 2059,
                                Tel: 138332, www.mycreditfile.com.au/{" "}
                              </li>
                              <li>
                                Experian Australia Credit Services Pty Ltd,
                                Level 6, 549 St Kilda Road, Melbourne VIC 3004,
                                Tel: 1300 784 684, www.experian.com.au{" "}
                              </li>
                              <li>
                                Creditor Watch, GPO Box 276, Sydney, NSW, 2001,
                                Tel: 1300 501 312, E:
                                support@creditorwatch.com.au,
                                www.creditorwatch.com.au/
                              </li>
                              <li>
                                Illion Australia (formerly trading as Dun and
                                Bradstreet (Australia) Pty Ltd), 479 St Kilda
                                Rd, Melbourne VIC 3004,
                                https://www.creditcheck.illion.com.au/,
                                Telephone: 1300 734 806
                              </li>
                            </ol>
                          </div>
                          <p>
                            These CRBs are each required to have a policy which
                            explains how they will manage your credit-related
                            personal information. To view the policy of any of
                            these CRBs please visit the relevant CRB’s website
                            and follow the "Privacy" links, or you can contact
                            them directly for further information.
                          </p>
                          <p>
                            We may also disclose credit related personal
                            information to our debt recovery agents, who may use
                            this to assist us in recovering debt owed to us.
                          </p>
                          <h5>
                            Accessing and correcting your credit information or
                            credit eligibility information
                          </h5>
                          <p>
                            If you wish to access or correct errors in any of
                            your credit information or credit eligibility
                            information that we hold, please contact us using
                            the contact details set out in the “Contacting us
                            about privacy and accessing your personal
                            information” section of our Privacy Policy.
                          </p>
                          <p>
                            We will assist you with accessing and correcting any
                            of your credit information or credit eligibility
                            information that we hold as described in the
                            “Contacting us about privacy and accessing your
                            personal information?” section of our Privacy
                            Policy. There is no charge to make a request for
                            access but we may apply an administration fee as
                            permitted by the Privacy Act 1988 for providing
                            access in accordance with your request.
                          </p>
                          <h5>How do you make a complaint?</h5>
                          <p>
                            If you think that we have not complied with Part
                            IIIA of the Privacy Act or with the Credit Reporting
                            Privacy Code (which regulates credit reporting) you
                            can make a complaint by using the contact details
                            set out in the “Contacting us about privacy and
                            accessing your personal information” section of our
                            Privacy Policy.
                          </p>
                          <p>
                            We will, where reasonably possible, take steps to
                            investigate and resolve complaints within 30 days.
                            If we need more time, we will notify you about the
                            reasons for the delay and seek to agree a longer
                            period with you (if you do not agree, we may not be
                            able to resolve your complaint). If we cannot
                            resolve a complaint related to your PI, you may
                            contact the Office of the Australian Information
                            Commissioner (“OAIC”) directly.
                          </p>
                          <h5>
                            Statement of Notifiable Matters under the Credit
                            Reporting Privacy Code
                          </h5>
                          <p>
                            Under the Credit Reporting Privacy Code, there are
                            several ‘notifiable matters’ that we are required to
                            disclose to you at or before the time of collecting
                            personal information that is likely to be disclosed
                            to a credit reporting body.
                          </p>
                          <p>Those matters are:</p>
                          <div className="lower-alpha">
                            <ol>
                              <li>
                                the credit reporting body may include the credit
                                information we provide to it in reports, which
                                it then provides to other credit providers to
                                assist those other credit providers to assess
                                your credit worthiness;
                              </li>
                              <li>
                                if you commit a serious credit infringement, we
                                may disclose this to a credit reporting body;
                              </li>
                              <li>
                                you can request a copy of this Credit Reporting
                                Policy by contacting us, or obtain it directly
                                from our website;
                              </li>
                              <li>
                                you can request a copy of the relevant credit
                                reporting bodies’ credit reporting policy from
                                its website or by contacting them directly by
                                mail. see above details under the section
                                “Credit reporting bodies to who we may share
                                credit –related personal information include:”.{" "}
                              </li>
                              <li>
                                you have the right to access credit information
                                we hold about you, request that we correct the
                                information, and make a complaint, as set out
                                further in the remainder of this Credit
                                Reporting Policy;
                              </li>
                              <li>
                                you can request a credit reporting body not to
                                use your credit reporting information for the
                                purposes of pre-screening of direct marketing by
                                us, and
                              </li>
                              <li>
                                you can request a credit reporting body not to
                                use or disclose your credit reporting
                                information if you believe on reasonable grounds
                                that you have been, or are likely to be, the
                                victim of fraud.
                              </li>
                            </ol>
                          </div>
                        </div>
                        <h5>Variation</h5>
                        <p>
                          LHA reserves the right to vary, replace or terminate
                          this policy at its discretion.
                        </p>
                      </div>
                    </div>
                    {/*TYPO3SEARCH_end*/}
                  </div>
                </div>
                {<SideBarVideo />}
              </div>
            </div>
          </div>
        </div>
        <div id="shadow-right" />
      </div>
    </>
  );
}

export default PrivacyPolicyPage;
