import SideBarVideo from "./SideBarVideo";

function ChainOfResponsibilityPage() {
  return (
    <>
      <div id="container01" className="container">
        <div id="shadow-left" />
        <div className="belowHeader single-page clearfix">
          {/* left main*/}
          {/* banner start*/}
          <div className="row">
            <div className="sixteen columns white">
              <div className="">
                <div className="htmlarea-content-body bootstrap">
                  <div id="c-2648" className="csc-default">
                    <div className="topContentMedia">
                      <img
                        className="scale-with-grid"
                        src="uploads/pics/header-news.png"
                        width={1024}
                        height={266}
                        alt="header banner"
                      />
                    </div>
                  </div>
                </div>
                <div id="c-7279" className="csc-default">
                  <div className="phone">
                    <div id="c-4514" className="csc-default">
                      <div className="row clearfix">
                        <a
                          href="http://btmsweb.bagtrans.com.au"
                          className="quicklink quicklink-world half1 clearfix"
                          title="Login"
                        >
                          <span className="world" />
                          <span className="quicklink-text location-header">
                            <strong className="specialFont1">Login</strong>
                            <br style={{ marginBottom: 5 }} />
                            <span>POD, Track &amp; Trace</span>
                          </span>
                        </a>
                      </div>
                    </div>
                    <div className="row clearfix">
                      <a
                        href="Contact"
                        className="quicklink quicklink-search half1 clearfix quicklink-customLink"
                        title="Locations"
                      >
                        <span className="locate" />
                        <span className="quicklink-text">
                          <strong className="specialFont1">Locations</strong>
                          <br style={{ marginBottom: 5 }} />
                          BagTrans Pty Ltd
                        </span>
                      </a>
                      <a
                        href="TransportQuery.php"
                        title="Query"
                        className="quicklink quicklink-customLink half2 clearfix omega"
                      >
                        <span className="telephone" />
                        <span className="quicklink-text">
                          <strong className="specialFont1">Query</strong>
                          <br style={{ marginBottom: 5 }} />
                          Transport Query
                        </span>
                      </a>
                    </div>
                  </div>
                  {/*  phone end */}
                </div>
              </div>
            </div>
          </div>
          {/* Banner end*/}
          {/* under slider*/}
          <div className="row single clearfix">
            <div className="sixteen columns white">
              <div className="content-wrapper clearfix">
                <div className="teen columns element-wrapper white clearfix left">
                  <div className="single-content-wrapper2">
                    {/*TYPO3SEARCH_begin*/}
                    <div className="htmlarea-content-body bootstrap">
                      {/*TYPO3SEARCH_end*/}
                      <div className="breadcrumb clearfix" />
                      {/*TYPO3SEARCH_begin*/}
                      <div id="c-2646" className="csc-default">
                        <hgroup>
                          <h2 className="csc-firstHeader">
                            Chain of Responsibility and fatigue management
                          </h2>
                        </hgroup>
                      </div>
                      {/* sub menu start*/}
                      <div id="c-3127" className="csc-default">
                        <div className="row-fluid">
                          <div className="h_line" />
                          <p>
                            The “chain” starts with you, the customer and
                            extends through to your customer (the receiver). As
                            a consignor you must understand the reasonable
                            expectations that may be placed on a driver and you
                            must act responsibly in consigning freight. This is
                            particularly important in relation to driver fatigue
                            and the setting of delivery deadlines.
                          </p>
                          <p>
                            By utilising the services of BagTrans you are
                            ensuring compliance with Chain of Responsibility
                            (COR). We provide copies of all necessary
                            documentation relating to COR in order for our
                            customers to have comfort in the knowledge that
                            BagTrans complies with all COR requirements.
                          </p>
                          <p>
                            We take the Chain of Responsibility very seriously
                            and we implore you to do the same.
                          </p>
                          <p>
                            Call our Sales team today and ask one of our
                            Business Development Managers for a more detailed
                            look at what we offer 1300 279 182. Alternatively
                            email us at &nbsp;
                            <a href="mailto:sales@BagTrans.com.au">
                              sales@BagTrans.com.au
                            </a>
                            .
                          </p>
                        </div>
                      </div>
                    </div>
                    {/*TYPO3SEARCH_end*/}
                  </div>
                </div>
                {<SideBarVideo />}
              </div>
            </div>
          </div>
        </div>
        <div id="shadow-right" />
      </div>
    </>
  );
}

export default ChainOfResponsibilityPage;
