import OurMissionPage from "./OurMissionPage";
import OurVisionPage from "./OurVisionPage";

function OurVisionAndMissionPage() {
  return (
    <>
      <OurVisionPage />
      <OurMissionPage />
    </>
  );
}

export default OurVisionAndMissionPage;
