import SideBarVideo from "./SideBarVideo";

function TermsAndConditionsPage() {
  return (
    <>
      <div id="container01" className="container">
        <div id="shadow-left" />
        <div className="belowHeader single-page clearfix">
          {/* left main*/}
          {/* banner start*/}
          <div className="row">
            <div className="sixteen columns white">
              <div className="">
                <div className="htmlarea-content-body bootstrap">
                  <div id="c-2648" className="csc-default">
                    <div className="topContentMedia">
                      <img
                        className="scale-with-grid"
                        src="uploads/pics/header-news.png"
                        width={1024}
                        height={266}
                        alt="header banner"
                      />
                    </div>
                  </div>
                </div>
                <div id="c-7279" className="csc-default">
                  <div className="phone">
                    <div id="c-4514" className="csc-default">
                      <div className="row clearfix">
                        <a
                          href="http://btmsweb.bagtrans.com.au"
                          className="quicklink quicklink-world half1 clearfix"
                          title="Login"
                        >
                          <span className="world" />
                          <span className="quicklink-text location-header">
                            <strong className="specialFont1">Login</strong>
                            <br style={{ marginBottom: 5 }} />
                            <span>POD, Track &amp; Trace</span>
                          </span>
                        </a>
                      </div>
                    </div>
                    <div className="row clearfix">
                      <a
                        href="Contact"
                        className="quicklink quicklink-search half1 clearfix quicklink-customLink"
                        title="Locations"
                      >
                        <span className="locate" />
                        <span className="quicklink-text">
                          <strong className="specialFont1">Locations</strong>
                          <br style={{ marginBottom: 5 }} />
                          BagTrans Pty Ltd
                        </span>
                      </a>
                      <a
                        href="TransportQuery.php"
                        title="Query"
                        className="quicklink quicklink-customLink half2 clearfix omega"
                      >
                        <span className="telephone" />
                        <span className="quicklink-text">
                          <strong className="specialFont1">Query</strong>
                          <br style={{ marginBottom: 5 }} />
                          Transport Query
                        </span>
                      </a>
                    </div>
                  </div>
                  {/*  phone end */}
                </div>
              </div>
            </div>
          </div>
          {/* Banner end*/}
          {/* under slider*/}
          <div className="row single clearfix">
            <div className="sixteen columns white">
              <div className="content-wrapper clearfix">
                <div className="teen columns element-wrapper white clearfix left">
                  <div className="single-content-wrapper2">
                    {/*TYPO3SEARCH_begin*/}
                    <div className="htmlarea-content-body bootstrap">
                      {/*TYPO3SEARCH_end*/}
                      <div className="breadcrumb clearfix" />
                      {/*TYPO3SEARCH_begin*/}
                      <div id="c-2646" className="csc-default">
                        <hgroup>
                          <h2 className="csc-firstHeader">
                            Terms and Conditions
                          </h2>
                        </hgroup>
                      </div>
                      {/* sub menu start*/}
                      <div id="c-3127" className="csc-default">
                        <div className="row-fluid white">
                          <div className="h_line" />
                          <p>
                            Lorem ipsum dolor sit amet. Eum ratione rerum est
                            dolor distinctio et architecto totam qui voluptatem
                            delectus. Qui possimus molestiae et unde harum vel
                            distinctio repudiandae ea galisum nihil ut
                            blanditiis quae.
                          </p>
                          <p>
                            Quo minus sint id nihil quos aut sunt sequi et
                            quaerat eveniet est possimus omnis et numquam saepe?
                            A voluptate repudiandae aut cupiditate magni et
                            minus pariatur et expedita rerum ea unde vitae qui
                            quis nobis et voluptatem ratione. Est cumque
                            asperiores qui dolores doloremque ea necessitatibus
                            repellendus ut aperiam dolor ut ratione quas non
                            molestiae sunt. Ut laudantium error et iure eius est
                            esse rerum et quod suscipit ut voluptatibus dolor ab
                            Quis nihil.
                          </p>
                          <p>
                            Qui rerum quod et accusamus numquam hic quibusdam
                            quia et minus voluptas? Ut odit fuga aut ipsam
                            dolorum est accusamus nihil At maiores odit. Eum
                            voluptas omnis non dignissimos voluptates ut
                            molestias culpa est culpa dolores ut ducimus
                            similique aut architecto natus ea omnis sint. Et
                            facilis voluptatem ea officiis tempore id nihil
                            nostrum.
                          </p>
                        </div>
                      </div>
                    </div>
                    {/*TYPO3SEARCH_end*/}
                  </div>
                </div>
                {<SideBarVideo />}
              </div>
            </div>
          </div>
          <div id="shadow-right" />
        </div>
      </div>
    </>
  );
}

export default TermsAndConditionsPage;
