import SideBarVideo from "./SideBarVideo";

function SiteMapPage() {
  return (
    <>
      <div id="container01" className="container">
        <div id="shadow-left" />
        <div className="belowHeader single-page clearfix">
          {/* left main*/}
          {/* banner start*/}
          <div className="row">
            <div className="sixteen columns white">
              <div className="">
                <div className="htmlarea-content-body bootstrap">
                  <div id="c-2648" className="csc-default">
                    <div className="topContentMedia">
                      <img
                        className="scale-with-grid"
                        src="uploads/pics/header-PrivacyPolicy.png"
                        width={1024}
                        height={266}
                        alt="header banner"
                      />
                    </div>
                  </div>
                </div>
                <div id="c-7279" className="csc-default">
                  <div className="phone">
                    <div id="c-4514" className="csc-default">
                      <div className="row clearfix">
                        <a
                          href="http://btmsweb.bagtrans.com.au"
                          className="quicklink quicklink-world half1 clearfix"
                          title="Login"
                        >
                          <span className="world" />
                          <span className="quicklink-text location-header">
                            <strong className="specialFont1">Login</strong>
                            <br style={{ marginBottom: 5 }} />
                            <span>POD, Track &amp; Trace</span>
                          </span>
                        </a>
                      </div>
                    </div>
                    <div className="row clearfix">
                      <a
                        href="Contact"
                        className="quicklink quicklink-search half1 clearfix quicklink-customLink"
                        title="Locations"
                      >
                        <span className="locate" />
                        <span className="quicklink-text">
                          <strong className="specialFont1">Locations</strong>
                          <br style={{ marginBottom: 5 }} />
                          BagTrans Pty Ltd
                        </span>
                      </a>
                      <a
                        href="TransportQuery.php"
                        title="Query"
                        className="quicklink quicklink-customLink half2 clearfix omega"
                      >
                        <span className="telephone" />
                        <span className="quicklink-text">
                          <strong className="specialFont1">Query</strong>
                          <br style={{ marginBottom: 5 }} />
                          Transport Query
                        </span>
                      </a>
                    </div>
                  </div>
                  {/*  phone end */}
                </div>
              </div>
            </div>
          </div>
          {/* Banner end*/}
          {/* under slider*/}
          <div className="row single clearfix">
            <div className="sixteen columns white">
              <div className="content-wrapper clearfix">
                <div className="teen columns element-wrapper white clearfix left">
                  <div className="single-content-wrapper2">
                    {/*TYPO3SEARCH_begin*/}
                    <div className="htmlarea-content-body bootstrap">
                      {/*TYPO3SEARCH_end*/}
                      <div className="breadcrumb clearfix" />
                      {/*TYPO3SEARCH_begin*/}
                      <div id="c-2646" className="csc-default">
                        <hgroup>
                          <h2 className="csc-firstHeader">Sitemap</h2>
                        </hgroup>
                      </div>
                      {/* sub menu start*/}
                      <div id="c-3127" className="csc-default">
                        <div className="row-fluid white">
                          <div className="h_line" />
                          <table
                            cellPadding={0}
                            cellSpacing={0}
                            border={0}
                            width="100%"
                          >
                            <tbody>
                              <tr>
                                <td className="lpage">
                                  <a href="/" title="BagTrans">
                                    BagTrans
                                  </a>
                                </td>
                              </tr>
                              <tr>
                                <td className="lpage">
                                  <a
                                    href="/PalletServices"
                                    title="BagTrans - Pallet Services"
                                  >
                                    BagTrans - Pallet Services
                                  </a>
                                </td>
                              </tr>
                              <tr>
                                <td className="lpage">
                                  <a
                                    href="/RailServices"
                                    title="BagTrans - Rail Services"
                                  >
                                    BagTrans - Rail Services
                                  </a>
                                </td>
                              </tr>
                              <tr>
                                <td className="lpage">
                                  <a
                                    href="/AboutUs"
                                    title="BagTrans - About Us"
                                  >
                                    BagTrans - About Us
                                  </a>
                                </td>
                              </tr>
                              <tr>
                                <td className="lpage">
                                  <a
                                    href="/CompanyProfile"
                                    title="BagTrans - Company Profile"
                                  >
                                    BagTrans - Company Profile
                                  </a>
                                </td>
                              </tr>
                              <tr>
                                <td className="lpage">
                                  <a
                                    href="/OurVision"
                                    title="BagTrans - Our Vision"
                                  >
                                    BagTrans - Our Vision
                                  </a>
                                </td>
                              </tr>
                              <tr>
                                <td className="lpage">
                                  <a
                                    href="/OurMission"
                                    title="BagTrans - Our Mission"
                                  >
                                    BagTrans - Our Mission
                                  </a>
                                </td>
                              </tr>
                              <tr>
                                <td className="lpage">
                                  <a
                                    href="/OurValues"
                                    title="BagTrans - Our Values"
                                  >
                                    BagTrans - Our Values
                                  </a>
                                </td>
                              </tr>
                              <tr>
                                <td className="lpage">
                                  <a
                                    href="/Services"
                                    title="BagTrans - Services"
                                  >
                                    BagTrans - Services
                                  </a>
                                </td>
                              </tr>
                              <tr>
                                <td className="lpage">
                                  <a
                                    href="/Technology"
                                    title="BagTrans - Technology"
                                  >
                                    BagTrans - Technology
                                  </a>
                                </td>
                              </tr>
                              <tr>
                                <td className="lpage">
                                  <a
                                    href="/WebBasedDispatch"
                                    title="BagTrans - Web Based Dispatch"
                                  >
                                    BagTrans - Web Based Dispatch
                                  </a>
                                </td>
                              </tr>
                              <tr>
                                <td className="lpage">
                                  <a
                                    href="/GPSTracking"
                                    title="BagTrans - GPS Tracking"
                                  >
                                    BagTrans - GPS Tracking
                                  </a>
                                </td>
                              </tr>
                              <tr>
                                <td className="lpage">
                                  <a
                                    href="/OnlineProofOfDelivery"
                                    title="BagTrans - Online Proof of Delivery"
                                  >
                                    BagTrans - Online Proof of Delivery
                                  </a>
                                </td>
                              </tr>
                              <tr>
                                <td className="lpage">
                                  <a
                                    href="/TrackTrace"
                                    title="BagTrans - Track & Trace"
                                  >
                                    BagTrans - Track &amp; Trace
                                  </a>
                                </td>
                              </tr>
                              <tr>
                                <td className="lpage">
                                  <a
                                    href="/SafetyAndCompliance"
                                    title="BagTrans - SAFETY, COMPLIANCE AND REPORTING"
                                  >
                                    BagTrans - Safety, Compliance And Reporting
                                  </a>
                                </td>
                              </tr>
                              <tr>
                                <td className="lpage">
                                  <a
                                    href="/ChainofResponsibility"
                                    title="BagTrans - CHAIN OF RESPONSIBILITY AND FATIGUE MANAGEMENT"
                                  >
                                    BagTrans - Chain of Responsibility and
                                    Fatigue Management
                                  </a>
                                </td>
                              </tr>
                              <tr>
                                <td className="lpage">
                                  <a
                                    href="/reporting"
                                    title="BagTrans - SAFETY, COMPLIANCE AND REPORTING"
                                  >
                                    BagTrans - Reporting
                                  </a>
                                </td>
                              </tr>
                              <tr>
                                <td className="lpage">
                                  <a
                                    href="/ClientLogin"
                                    title="BagTrans - About Us"
                                  >
                                    BagTrans - About Us
                                  </a>
                                </td>
                              </tr>
                              <tr>
                                <td className="lpage">
                                  <a
                                    href="/Contact"
                                    title="BagTrans - Contact "
                                  >
                                    BagTrans - Contact
                                  </a>
                                </td>
                              </tr>
                              <tr>
                                <td className="lpage">
                                  <a
                                    href="/NewsInformation"
                                    title="BagTrans - News & Information"
                                  >
                                    BagTrans - News &amp; Information
                                  </a>
                                </td>
                              </tr>
                              <tr>
                                <td className="lpage">
                                  <a
                                    href="http://www.bagtrans.com.au/TransportQuery.php"
                                    title="BagTrans - Transport Query"
                                  >
                                    BagTrans - Transport Query
                                  </a>
                                </td>
                              </tr>
                              <tr>
                                <td className="lpage">
                                  <a
                                    href="/Privacy-Policy"
                                    title="BagTrans - Privacy Policy"
                                  >
                                    BagTrans - Privacy Policy
                                  </a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      {/* sub menu end*/}
                    </div>
                    {/*TYPO3SEARCH_end*/}
                  </div>
                </div>
                {<SideBarVideo />}
                <div id="c-3127" className="csc-default">
                  <div className="row-fluid white">
                    <div className="span4" />
                  </div>
                  {/* c-3127 end here*/}
                </div>
                {/* under header main contain */}
              </div>
            </div>
          </div>
          <div id="shadow-right" />
        </div>
      </div>
    </>
  );
}

export default SiteMapPage;
