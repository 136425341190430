import React from "react";
import { useLocation } from "react-router-dom";

function SideBarVideo() {
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <div id="sidebar" className="six columns">
      <div className="bootstrap">
        <div id="c-7279" className="csc-default">
          <div className="phone">
            <div id="c-4514" className="csc-default">
              <div className="row clearfix">
                <a
                  className="quicklink quicklink-world half1 clearfix"
                  title="Login"
                  href="http://btmsweb.bagtrans.com.au"
                  target="_blank"
                >
                  <span className="world" />
                  <span className="quicklink-text location-header">
                    <strong className="specialFont1">Login</strong>
                    <br style={{ marginBottom: 5 }} />
                    <span>POD, Track &amp; Trace</span>
                  </span>
                </a>
              </div>
            </div>
            <div className="row clearfix">
              <a
                className="quicklink quicklink-search half1 clearfix quicklink-customLink"
                title="Contact"
                href="Contact"
              >
                <span className="locate" />
                <span className="quicklink-text">
                  <strong className="specialFont1">Locations</strong>
                  <br style={{ marginBottom: 5 }} />
                  BagTrans Pty Ltd
                </span>
              </a>
              <a
                href="/standorte/"
                title="Telephone"
                className="quicklink quicklink-customLink half2 clearfix omega"
              >
                <span className="telephone" />
                <span className="quicklink-text">
                  <strong className="specialFont1">Telephone</strong>
                  <br style={{ marginBottom: 5 }} />
                  1300 279 182
                </span>
              </a>
            </div>
          </div>
          {/*  phone end */}
          <aside id="sidebar-login" className="sidebar sidebar-slider">
            <ul>
              <li>
                <span className="bubble">
                  <a
                    title="BTMS Online Login"
                    href="http://btmsweb.bagtrans.com.au"
                    target="_blank"
                  >
                    <span className="login" />
                    <strong>Login</strong>
                    <br />
                    POD, Track &amp; Trace
                  </a>
                </span>
              </li>
              <li>
                <span className="bubble">
                  <a
                    href="TransportQuery.php"
                    title="Query"
                    className="customLink"
                  >
                    <span className="telephone" />
                    <strong>Query</strong>
                    <br />
                    Transport Query
                  </a>
                </span>
              </li>
              <li>
                <span className="bubble">
                  <a title="Locations" href="Contact" className="customLink">
                    <span className="locate" />
                    <strong>Locations</strong>
                    <br />
                    BagTrans Pty Ltd
                  </a>
                </span>
              </li>
            </ul>
          </aside>
        </div>
        {/*right sider top end*/}
        {/* right  sider */}
        <div id="c-4514" className="csc-default">
          <aside id="sidebar-gw-world" className="boxlink sidebar">
            <p>Overview of the contact details of our national network</p>
            <img
              className="scale-with-grid"
              alt=""
              src="media/layout/sidebar-gw-world-map.png"
              style={{ maxWidth: "100%" }}
            />
            <a
              className="normal-button orange"
              href="Contact"
              title="Find your contact within our national network"
            >
              Find address <span className="arrow-right" />{" "}
            </a>
            <br />
            {currentPath !== "/our-video" && (
              <>
                <p style={{ fontWeight: "bold" }}>
                  <a href="our-video">Watch our corporate video</a>
                </p>
                <a href="our-video">
                  <img src="media/layout/video-img.jpg" />
                </a>
              </>
            )}
          </aside>
        </div>
        {/* right  sider end*/}
      </div>
    </div>
  );
}

export default SideBarVideo;
