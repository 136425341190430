import SideBarVideo from "./SideBarVideo";
import { useNavigate } from "react-router-dom";

function ClientLoginPage() {
  const navigate = useNavigate();
  return (
    <>
      <div id="container01" className="container">
        <div id="shadow-left" />
        <div className="belowHeader single-page clearfix">
          {/* left main*/}
          {/* banner start*/}
          <div className="row">
            <div className="sixteen columns white">
              <div className="">
                <div className="htmlarea-content-body bootstrap">
                  <div id="c-2648" className="csc-default">
                    <div className="topContentMedia">
                      <img
                        className="scale-with-grid"
                        src="uploads/pics/header-news.png"
                        width={1024}
                        height={266}
                        alt="header banner"
                      />
                    </div>
                  </div>
                </div>
                <div id="c-7279" className="csc-default">
                  <div className="phone">
                    <div id="c-4514" className="csc-default">
                      <div className="row clearfix">
                        <a
                          href="http://btmsweb.bagtrans.com.au"
                          className="quicklink quicklink-world half1 clearfix"
                          title="Login"
                        >
                          <span className="world" />
                          <span className="quicklink-text location-header">
                            <strong className="specialFont1">Login</strong>
                            <br style={{ marginBottom: 5 }} />
                            <span>POD, Track &amp; Trace</span>
                          </span>
                        </a>
                        <a
                          href="http://btms.bagtrans.com.au/default.aspx?cmd=reg"
                          className="quicklink quicklink-geo half2 clearfix"
                          title="Registration"
                        >
                          <span className="geo" />
                          <span className="quicklink-text">
                            <strong className="specialFont1">
                              Registration
                            </strong>
                            <br style={{ marginBottom: 5 }} />
                            POD, Track &amp; Trace
                          </span>
                        </a>
                      </div>
                    </div>
                    <div className="row clearfix">
                      <a
                        href="Contact"
                        className="quicklink quicklink-search half1 clearfix quicklink-customLink"
                        title="Locations"
                      >
                        <span className="locate" />
                        <span className="quicklink-text">
                          <strong className="specialFont1">Locations</strong>
                          <br style={{ marginBottom: 5 }} />
                          BagTrans Pty Ltd
                        </span>
                      </a>
                      <a
                        href="TransportQuery.php"
                        title="Query"
                        className="quicklink quicklink-customLink half2 clearfix omega"
                      >
                        <span className="telephone" />
                        <span className="quicklink-text">
                          <strong className="specialFont1">Query</strong>
                          <br style={{ marginBottom: 5 }} />
                          Transport Query
                        </span>
                      </a>
                    </div>
                  </div>
                  {/*  phone end */}
                </div>
              </div>
            </div>
          </div>
          {/* Banner end*/}
          {/* under slider*/}
          <div className="row single clearfix">
            <div className="sixteen columns white">
              <div className="content-wrapper clearfix">
                <div className="teen columns element-wrapper white clearfix left">
                  <div className="single-content-wrapper2">
                    {/*TYPO3SEARCH_begin*/}
                    <div className="htmlarea-content-body bootstrap">
                      {/*TYPO3SEARCH_end*/}
                      <div className="breadcrumb clearfix" />
                      {/*TYPO3SEARCH_begin*/}
                      <div id="c-2646" className="csc-default">
                        <hgroup>
                          <h2 className="csc-firstHeader">Client Login</h2>
                        </hgroup>
                      </div>
                      {/* sub menu start*/}
                      <div id="c-3127" className="csc-default">
                        <div className="row-fluid white">
                          <div className="h_line" />
                          <p>
                            The BagTrans web-based consignment dispatch
                            facility, BTMS Web, provides customers with the
                            functionality to view Proof of Delivery (PoD) and
                            Track and Trace updates.
                          </p>
                          <p />
                        </div>
                      </div>
                    </div>
                    {/*TYPO3SEARCH_end*/}
                  </div>
                </div>
                {<SideBarVideo />}
                <div id="c-3127" className="csc-default">
                  <div className="row-fluid white">
                    <div className="span4">
                      <div id="c-3131" className="csc-default">
                        {/* 5 */}
                        <div
                          className="overviewElement design-0 boxlink element"
                          onClick={() => navigate("/")}
                        >
                          <span className="img-overlay">
                            <span className="img-overlay-bg">
                              <span className="img-overlay-wrapper">
                                <span className="img-overlay-head">
                                  BTMS Web
                                  <span className="slide-content-button" />
                                </span>
                                <span className="img-overlay-subhead">
                                  More Information
                                </span>
                              </span>
                            </span>
                            <img
                              className="scale-with-grid"
                              src="uploads/pics/sub-menu-15.png"
                              width={224}
                              height={190}
                              alt=""
                            />
                          </span>
                          <h3>
                            <a href="/" target="_blank" rel="noreferrer">
                              BTMS Web
                            </a>
                          </h3>
                        </div>
                      </div>
                    </div>
                    {/* 3 end */}
                  </div>
                  {/* c-3127 end here*/}
                </div>
                {/* under header main contain */}
              </div>
            </div>
          </div>
          <div id="shadow-right" />
        </div>
      </div>
    </>
  );
}

export default ClientLoginPage;
