function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer>
      <div className="row desktop">
        <div className="sixteen columns clearfix">
          <div className="shadow-bottom"></div>
          <div className="top-footer blue clearfix">
            <ul className="left clearfix">
              <li className="copyright">© {currentYear} BagTrans Pty Ltd Website</li>
              <li>
                <a href="sitemap">Sitemap</a>
              </li>
              <li>
                <a href="Contact">Contact</a>
              </li>
              <li className="omega">
                <a href="Privacy-Policy">Privacy Policy</a>
              </li>
            </ul>            
            <div className="social-media right">
              <ul className="clearfix">
                <li>
                  <a
                    className="facebook"
                    title="Facebook"
                    href="https://www.facebook.com/pages/Bagtrans/227665767376688?__tn__=HH-R"
                  />
                </li>
                <li>
                  <a
                    className="linkedin"
                    title="LinkedIn"
                    href="https://au.linkedin.com/company/bagtrans"
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="row phone">
        <div className="sixteen columns clearfix">
          <div className="shadow-bottom"></div>
          <div className="top-footer blue clearfix">            
            <div className="social-media">
              <ul className="clearfix">
                <li>
                  <a
                    className="facebook"
                    title="Facebook"
                    href="https://www.facebook.com/pages/Bagtrans/227665767376688?__tn__=HH-R"
                  />
                </li>
                <li>
                  <a
                    className="linkedin"
                    title="LinkedIn"
                    href="https://au.linkedin.com/company/bagtrans"
                  />
                </li>
              </ul>
            </div>
            <ul className="left clearfix">
            <li className="copyright">© {currentYear} BagTrans Pty Ltd Website</li>
              <li>
                <a href="sitemap">Sitemap</a>
              </li>
              <li>
                <a href="Contact">Contact</a>
              </li>
              <li className="omega">
                <a href="Privacy-Policy">Privacy Policy</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
