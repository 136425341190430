/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

function Slider() {
  React.useEffect(() => {
    window.app.initSlider();
  });

  return (
    <div className="sixteen columns white">
      <div className="htmlarea-content-body bootstrap">
        <div id="c-3346" className="csc-default">
          <div className="flexslider desktop slider1">
            <ul className="slides clearfix">
              {/* sdlier 1 */}
              <li className="boxlink">
                <div className="slide-content">
                  <h2>
                    <a href=""> A winning formula </a>
                  </h2>
                  <p>
                    <span lang="DE">
                      Having the right people, experience, purpose-built systems
                      and equipment all come together to provide a superior
                      service.
                    </span>
                  </p>
                  <span className="slide-content-button">{/* */}</span>
                </div>
                {/* /slide -content */}
                <img
                  className="scale-with-grid"
                  alt=""
                  src="uploads/pics/slider-1.png"
                  width={1024}
                  height={400}
                  title="A winning Formula"
                />
              </li>
              {/* sdlier 1 end */}
              {/* sdlier 2 start */}
              <li className="boxlink">
                <div className="slide-content">
                  <h2>
                    <a href=""> Delivering FMCG to major retail DCs </a>
                  </h2>
                  <p>
                    <span lang="DE">
                      BagTrans has built a strong reputation as being the
                      experts in time-slotted delivery of palletised goods to
                      all major DCs throughout Australia.
                    </span>
                  </p>
                  <span className="slide-content-button">{/* */}</span>
                </div>
                {/* /slide-content */}
                <img
                  className="scale-with-grid"
                  alt=""
                  src="uploads/pics/slider-2.png"
                  width={1024}
                  height={400}
                  title="Delivering FMCG to Major retail DCs"
                />
              </li>
              {/* sdlier 2 end */}
              {/* sdlier 3 start */}
              <li className="boxlink">
                <div className="slide-content">
                  <h2>
                    <a href="">Improve your supply chain with BagTrans</a>
                  </h2>
                  <p>
                    <span lang="DE">
                      We are able to support your supply chain success from your
                      warehouse door to delivering on time.
                    </span>
                  </p>
                  <span className="slide-content-button">{/* */}</span>
                </div>
                {/* /slide-content */}
                <img
                  className="scale-with-grid"
                  alt=""
                  src="uploads/pics/slider-3.png"
                  width={1024}
                  height={400}
                  title="Improve your supply chain with BagTrans"
                />
              </li>
              {/* sdlier 3 end */}
              {/* sdlier 4 start */}
              <li className="boxlink">
                <div className="slide-content">
                  <h2>
                    <a href="">
                      Looking for specially tailored expert solutions to
                      guarantee your supply chain performance?
                    </a>
                  </h2>
                  <p>
                    <span lang="DE">
                      As a major Australian player in LTL palletised transport
                      we are already addressing challenges for the FMCG sector.
                      Why not let us meet yours?
                    </span>
                  </p>
                  <span className="slide-content-button">{/* */}</span>
                </div>
                {/* /slide-content */}
                <img
                  className="scale-with-grid"
                  alt=""
                  src="uploads/pics/slider-4.png"
                  width={1024}
                  height={400}
                  title="Looking for specially tailored expert solutions to guarantee your supply chain performance?"
                />
              </li>
              {/* sdlier 4 end */}
              {/* sdlier 6 start */}
              <li className="boxlink">
                <div className="slide-content">
                  <h2>
                    <a href="">
                      Looking for a delivery network, which combines quality of
                      service with the efficiency of an expert?
                    </a>
                  </h2>
                  <p>
                    <span lang="DE">
                      Every day, the BagTrans team ensures that your pallets are
                      delivered at the right time to the right place, which may
                      be a local delivery or on the other side of Australia. Our
                      experts take full responsibility of your scheduled times
                      and, most importantly, your brand image.
                    </span>
                  </p>
                  <span className="slide-content-button">{/* */}</span>
                </div>
                {/* /slide-content */}
                <img
                  className="scale-with-grid"
                  alt=""
                  src="uploads/pics/slider-6.png"
                  width={1024}
                  height={400}
                  title="Looking for a delivery network, which combines quality of service with the efficiency of an expert?"
                />
              </li>
              {/* sdlier 6 end */}
              {/* sdlier 7 start */}
              <li className="boxlink">
                <div className="slide-content">
                  <h2>
                    <a href="">
                      Need a reliable partner to take care of your transport
                      needs throughout Australia?
                    </a>
                  </h2>
                  <p>
                    <span lang="DE" />
                  </p>
                  <span className="slide-content-button">{/* */}</span>
                </div>
                {/* /slide-content */}
                <img
                  className="scale-with-grid"
                  alt=""
                  src="uploads/pics/slider-7.png"
                  width={1024}
                  height={400}
                  title="Need a reliable partner to take care of your transport needs throughout Australia?"
                />
              </li>
              {/* sdlier 7 end */}
            </ul>
          </div>
          {/* slider on desktop end */}
          {/* slider on pad start */}
          <div className="flexslider pad slider1">
            <ul className="slides clearfix">
              {/* slider 1 pad */}
              <li className="boxlink">
                <div className="slide-content">
                  <h2>
                    <a href=""> A winning formula </a>
                  </h2>
                  <p>
                    <span lang="DE">
                      Having the right people, experience, purpose-built systems
                      and equipment all come together to provide a superior
                      service.
                    </span>
                  </p>
                  <span className="slide-content-button">{/* */}</span>
                </div>
                {/* /slide-content */}
                <img
                  className="scale-with-grid"
                  alt=""
                  src="uploads/pics/slider-1.png"
                  width={768}
                  height={400}
                  title="A winning Formula"
                />
              </li>
              {/* slider 1 pad end*/}
              {/* slider 2 pad */}
              <li className="boxlink">
                <div className="slide-content">
                  <h2>
                    <a href=""> Delivering FMCG to major retail DCs </a>
                  </h2>
                  <p>
                    <span lang="DE">
                      BagTrans has built a strong reputation as being the
                      experts in time-slotted delivery of palletised goods to
                      all major DCs throughout Australia.
                    </span>
                  </p>
                  <span className="slide-content-button">{/* */}</span>
                </div>
                {/* /slide-content */}
                <img
                  className="scale-with-grid"
                  alt=""
                  src="uploads/pics/slider-2.png"
                  width={768}
                  height={400}
                  title="Delivering FMCG to Major retail DCs"
                />
              </li>
              {/* slider 2 pad END*/}
              {/* slider 3 pad */}
              <li className="boxlink">
                <div className="slide-content">
                  <h2>
                    <a href="">Improve your supply chain with BagTrans</a>
                  </h2>
                  <p>
                    <span lang="DE">
                      We are able to support your supply chain success from your
                      warehouse door to delivering on time.
                    </span>
                  </p>
                  <span className="slide-content-button">{/* */}</span>
                </div>
                {/* /slide-content */}
                <img
                  className="scale-with-grid"
                  alt=""
                  src="uploads/pics/slider-3.png"
                  width={768}
                  height={400}
                  title="Improve your supply chain with BagTrans"
                />
              </li>
              {/* slider 3 pad END*/}
              {/* slider 4 pad */}
              <li className="boxlink">
                <div className="slide-content">
                  <h2>
                    <a href="">
                      Looking for specially tailored expert solutions to
                      guarantee your supply chain performance?
                    </a>
                  </h2>
                  <p>
                    <span lang="DE">
                      As a major Australian player in LTL palletised transport
                      we are already addressing challenges for the FMCG sector.
                      Why not let us meet yours?
                    </span>
                  </p>
                  <span className="slide-content-button">{/* */}</span>
                </div>
                {/* /slide-content */}
                <img
                  className="scale-with-grid"
                  alt=""
                  src="uploads/pics/slider-4.png"
                  width={768}
                  height={400}
                  title="Looking for specially tailored expert solutions to guarantee your supply chain performance?"
                />
              </li>
              {/* slider 6 pad */}
              <li className="boxlink">
                <div className="slide-content">
                  <h2>
                    <a href="">
                      Looking for a delivery network, which combines quality of
                      service with the efficiency of an expert?
                    </a>
                  </h2>
                  <p>
                    <span lang="DE">
                      Every day, the BagTrans team ensures that your pallets are
                      delivered at the right time to the right place, which may
                      be a local delivery or on the other side of Australia. Our
                      experts take full responsibility of your scheduled times
                      and, most importantly, your brand image.
                    </span>
                  </p>
                  <span className="slide-content-button">{/* */}</span>
                </div>
                {/* /slide-content */}
                <img
                  className="scale-with-grid"
                  alt=""
                  src="uploads/pics/slider-6.png"
                  width={768}
                  height={400}
                  title="Looking for a delivery network, which combines quality of service with the efficiency of an expert?"
                />
              </li>
              {/* slider 6 pad END*/}
              {/* slider 7 pad */}
              <li className="boxlink">
                <div className="slide-content">
                  <h2>
                    <a href="">
                      Need a reliable partner to take care of your transport
                      needs throughout Australia?
                    </a>
                  </h2>
                  <p>
                    <span lang="DE" />
                  </p>
                  <span className="slide-content-button">{/* */}</span>
                </div>
                {/* /slide-content */}
                <img
                  className="scale-with-grid"
                  alt="transport needs"
                  src="uploads/pics/slider-7.png"
                  width={768}
                  height={400}
                  title="Need a reliable partner to take care of your transport needs throughout Australia?"
                />
              </li>
              {/* slider 6 pad END*/}
            </ul>
          </div>
          {/* slider on pad END */}
          {/* slider on pad-mini start */}
          <div className="flexslider pad-mini slider1">
            <ul className="slides clearfix">
              {/* slider 1 pad-mini */}
              <li className="boxlink">
                <div className="slide-content">
                  <h2>
                    <a href=""> A winning formula </a>
                  </h2>
                  <p>
                    <span lang="DE">
                      Having the right people, experience, purpose built systems
                      and equipment all come together to provide a superior
                      service.
                    </span>
                  </p>
                  <span className="slide-content-button">{/* */}</span>
                </div>
                {/* /slide-content */}
                <img
                  className="scale-with-grid"
                  alt=""
                  src="uploads/pics/slider-1.png"
                  width={640}
                  height={250}
                  title="A winning Formula"
                />
              </li>
              {/* slider 1 pad-mini end*/}
              {/* slider 2 pad-mini */}
              <li className="boxlink">
                <div className="slide-content">
                  <h2>
                    <a href=""> Delivering FMCG to major retail DCs </a>
                  </h2>
                  <p>
                    <span lang="DE">
                      BagTrans has built a strong reputation as being the
                      experts in time-slotted delivery of palletised goods to
                      all major DCs throughout Australia.
                    </span>
                  </p>
                  <span className="slide-content-button">{/* */}</span>
                </div>
                {/* /slide-content */}
                <img
                  className="scale-with-grid"
                  alt=""
                  src="uploads/pics/slider-2.png"
                  width={640}
                  height={250}
                  title="Delivering FMCG to Major retail DCs"
                />
              </li>
              {/* slider 2 pad-mini end*/}
              {/* slider 3 pad-mini */}
              <li className="boxlink">
                <div className="slide-content">
                  <h2>
                    <a href="">Improve your supply chain with BagTrans</a>
                  </h2>
                  <p>
                    <span lang="DE">
                      We are able to support your supply chain success from your
                      warehouse door to delivering on time.
                    </span>
                  </p>
                  <span className="slide-content-button">{/* */}</span>
                </div>
                {/* /slide-content */}
                <img
                  className="scale-with-grid"
                  alt=""
                  src="uploads/pics/slider-3.png"
                  width={640}
                  height={250}
                  title="Improve your supply chain with BagTrans"
                />
              </li>
              {/* slider 3 pad-mini end*/}
              {/* slider 4 pad-mini */}
              <li className="boxlink">
                <div className="slide-content">
                  <h2>
                    <a href="">
                      Looking for specially tailored expert solutions to
                      guarantee your supply chain performance?
                    </a>
                  </h2>
                  <p>
                    <span lang="DE">
                      As a major Australian player in LTL palletised transport
                      we are already addressing challenges for the FMCG sector.
                      Why not let us meet yours?
                    </span>
                  </p>
                  <span className="slide-content-button">{/* */}</span>
                </div>
                {/* /slide-content */}
                <img
                  className="scale-with-grid"
                  alt=""
                  src="uploads/pics/slider-4.png"
                  width={640}
                  height={250}
                  title="Looking for specially tailored expert solutions to guarantee your supply chain performance?"
                />
              </li>
              {/* slider 4 pad-mini end*/}
              {/* slider 6 pad-mini */}
              <li className="boxlink">
                <div className="slide-content">
                  <h2>
                    <a href="">
                      Looking for a delivery network, which combines quality of
                      service with the efficiency of an expert?
                    </a>
                  </h2>
                  <p>
                    <span lang="DE">
                      Every day, the BagTrans team ensures that your pallets are
                      delivered at the right time to the right place, which may
                      be a local delivery or on the other side of Australia. Our
                      experts take full responsibility of your scheduled times
                      and, most importantly, your brand image.
                    </span>
                  </p>
                  <span className="slide-content-button">{/* */}</span>
                </div>
                {/* /slide-content */}
                <img
                  className="scale-with-grid"
                  alt=""
                  src="uploads/pics/slider-6.png"
                  width={640}
                  height={250}
                  title="Looking for a delivery network, which combines quality of service with the efficiency of an expert?"
                />
              </li>
              {/* slider 6 pad-mini end*/}
              {/* slider 7 pad-mini */}
              <li className="boxlink">
                <div className="slide-content">
                  <h2>
                    <a href="">
                      Need a reliable partner to take care of your transport
                      needs throughout Australia?
                    </a>
                  </h2>
                  <p>
                    <span lang="DE" />
                  </p>
                  <span className="slide-content-button">{/* */}</span>
                </div>
                {/* /slide-content */}
                <img
                  className="scale-with-grid"
                  alt="transport needs"
                  src="uploads/pics/slider-7.png"
                  width={640}
                  height={250}
                  title="Need a reliable partner to take care of your transport needs throughout Australia?"
                />
              </li>
              {/* slider 7 pad-mini end*/}
            </ul>
          </div>
          {/* slider on pad-mini END */}
          {/* slider on phone start */}
          <div className="flexslider phone slider1">
            <ul className="slides clearfix">
              {/* slider 1 phone */}
              <li className="boxlink">
                <div className="slide-content">
                  <h2>
                    <a href=""> A winning formula </a>
                  </h2>
                  <p>
                    <span lang="DE">
                      Having the right people, experience, purpose-built systems
                      and equipment all come together to provide a superior
                      service.
                    </span>
                  </p>
                  <span className="slide-content-button">{/* */}</span>
                </div>
                {/* /slide-content */}
                <img
                  className="scale-with-grid"
                  alt=""
                  src="uploads/pics/slider-5.png"
                  width={420}
                  height={289}
                  title="A winning Formula"
                />
              </li>
              {/* slider 1 phone end*/}
              {/* slider 2 phone */}
              <li className="boxlink">
                <div className="slide-content">
                  <h2>
                    <a href=""> Delivering FMCG to major retail DCs </a>
                  </h2>
                  <p>
                    <span lang="DE">
                      BagTrans has built a strong reputation as being the
                      experts in time-slotted delivery of palletised goods to
                      all major DCs throughout Australia.
                    </span>
                  </p>
                  <span className="slide-content-button">{/* */}</span>
                </div>
                {/* /slide-content */}
                <img
                  className="scale-with-grid"
                  alt=""
                  src="uploads/pics/slider-6.png"
                  width={420}
                  height={289}
                  title="Delivering FMCG to Major retail DCs"
                />
              </li>
              {/* slider 2 phone end*/}
              {/* slider 3 phone */}
              <li className="boxlink">
                <div className="slide-content">
                  <h2>
                    <a href="">Improve your supply chain with BagTrans</a>
                  </h2>
                  <p>
                    <span lang="DE">
                      We are able to support your supply chain success from your
                      warehouse door to delivering on time.
                    </span>
                  </p>
                  <span className="slide-content-button">{/* */}</span>
                </div>
                {/* /slide-content */}
                <img
                  className="scale-with-grid"
                  alt=""
                  src="uploads/pics/slider-7.png"
                  width={420}
                  height={289}
                  title="Improve your supply chain with BagTrans"
                />
              </li>
              {/* slider 3 phone end*/}
              {/* slider 4 phone */}
              <li className="boxlink">
                <div className="slide-content">
                  <h2>
                    <a href="">
                      Looking for specially tailored expert solutions to
                      guarantee your supply chain performance?
                    </a>
                  </h2>
                  <p>
                    <span lang="DE">
                      As a major Australian player in LTL palletised transport
                      we are already addressing challenges for the FMCG sector.
                      Why not let us meet yours?
                    </span>
                  </p>
                  <span className="slide-content-button">{/* */}</span>
                </div>
                {/* /slide-content */}
                <img
                  className="scale-with-grid"
                  alt=""
                  src="uploads/pics/slider-4.png"
                  width={420}
                  height={289}
                  title="Looking for specially tailored expert solutions to guarantee your supply chain performance?"
                />
              </li>
              {/* slider 4 phone END*/}
              {/* slider 6 phone */}
              <li className="boxlink">
                <div className="slide-content">
                  <h2>
                    <a href="">
                      Looking for a delivery network, which combines quality of
                      service with the efficiency of an expert?
                    </a>
                  </h2>
                  <p>
                    <span lang="DE">
                      Every day, the BagTrans team ensures that your pallets are
                      delivered at the right time to the right place, which may
                      be a local delivery or on the other side of Australia. Our
                      experts take full responsibility of your scheduled times
                      and, most importantly, your brand image.
                    </span>
                  </p>
                  <span className="slide-content-button">{/* */}</span>
                </div>
                {/* /slide-content */}
                <img
                  className="scale-with-grid"
                  alt=""
                  src="uploads/pics/slider-6.png"
                  width={420}
                  height={289}
                  title="Looking for a delivery network, which combines quality of service with the efficiency of an expert?"
                />
              </li>
              {/* slider 6 phone END*/}
              {/* slider 7 phone */}
              <li className="boxlink">
                <div className="slide-content">
                  <h2>
                    <a href="">
                      Need a reliable partner to take care of your transport
                      needs throughout Australia?
                    </a>
                  </h2>
                  <p>
                    <span lang="DE" />
                  </p>
                  <span className="slide-content-button">{/* */}</span>
                </div>
                {/* /slide-content */}
                <img
                  className="scale-with-grid"
                  alt=""
                  src="uploads/pics/slider-7.png"
                  width={420}
                  height={289}
                  title="Need a reliable partner to take care of your transport needs throughout Australia?"
                />
              </li>
              {/* slider 7 phone END*/}
            </ul>
          </div>
          {/* slider on phone end */}
        </div>
      </div>
      <div id="c-7279" className="csc-default">
        <div className="phone">
          <div id="c-4514" className="csc-default">
            <div className="row clearfix">
              <a
                href="http://btmsweb.bagtrans.com.au/default.aspx?cmd=login"
                className="quicklink quicklink-world half1 clearfix"
                title="BTMS Online login"
              >
                <span className="world" />
                <span className="quicklink-text location-header">
                  <strong className="specialFont1">Login</strong>
                  <br style={{ marginBottom: 5 }} />
                  <span>POD, Track &amp; Trace</span>
                </span>
              </a>
            </div>
          </div>
          <div className="row clearfix">
            <a
              href="Contact"
              className="quicklink quicklink-search half1 clearfix quicklink-customLink"
              title="Contact"
            >
              <span className="locate" />
              <span className="quicklink-text">
                <strong className="specialFont1">Locations</strong>
                <br style={{ marginBottom: 5 }} />
                BagTrans Pty Ltd
              </span>
            </a>
            <a
              href="TransportQuery.php"
              title="Query"
              className="quicklink quicklink-customLink half2 clearfix omega"
            >
              <span className="telephone" />
              <span className="quicklink-text">
                <strong className="specialFont1">Query</strong>
                <br style={{ marginBottom: 5 }} />
                Transport Query
              </span>
            </a>
          </div>
        </div>
        {/*  phone end */}
      </div>
    </div>
  );
}

export default Slider;
